import { trackPromise } from "react-promise-tracker";

export function getWeatherAlerts(): Promise<object> {
    // this.context.reloadCurrentProject;
    const alertSet = new Set();
    this.state.new_weather_alert_enabled.map((alert) => alertSet.add(alert));
    const alertArray = Array.from(alertSet);
    return Promise.all(
        alertArray.map((alert) => {
            if (alert === "enable_lightning_alerts") {
                return this.getLightningAlerts();
            }
            if (alert === "enable_weather_severe") {
                return this.getSevereAlerts();
            }
            if (alert === "enable_weather_threats") {
                return this.getThreatsAlerts();
            }
        })
    );
}

// TODO: WEBAPP-781
export function getThreatsAlerts(projectInfo, AERIS_API_ID, AERIS_API_KEY): Promise<any> {
    const url = `https://api.aerisapi.com/threats/${projectInfo.lat},${projectInfo.lon}?format=json&client_id=${AERIS_API_ID}&client_secret=${AERIS_API_KEY}`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.length === 0) {
                    return null;
                }
                return response;
            })
            .then((response) => {
                for (const threat of response.response) {
                    if (threat.periods[0].storms) {
                        const threat_alert = {};
                        threat_alert.affected_jobsites = [projectInfo.unique_token];
                        threat_alert.alert_time = threat.periods[0].dateTimeISO;
                        threat_alert.alert_type = "threat";
                        threat_alert.alert_id = "thr_" + threat.periods[0].timestamp;
                        threat_alert.alert_info = JSON.stringify(threat);
                        this.postWeatherAlert(threat_alert);
                    }
                }
            })
            .catch(() => {
                return null;
            })
    );
}
// TODO: WEBAPP-781
export function getLightningAlerts(projectInfo, AERIS_API_ID, AERIS_API_KEY): Promise<any> {
    const fromSeconds = projectInfo.lightning_all_clear_duration * 60;
    const toSeconds = projectInfo.lightning_all_clear_duration * 60;
    const url = `https://api.aerisapi.com/lightning/closest?p=/${projectInfo.lat},${projectInfo.lon}&format=geojson&filter=cg&client_id=${AERIS_API_ID}&client_secret=${AERIS_API_KEY}&from=-${fromSeconds}seconds&to=${toSeconds}seconds&radius=${projectInfo.lightning_alert_distance}&limit=100`;

    return fetch(url, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((response) => {
            for (const strike of response.features) {
                const new_strike = JSON.parse(JSON.stringify(strike));
                new_strike.affected_projects = [projectInfo.unique_token];
                new_strike.alert_time = strike.properties.recISO;
                new_strike.alert_type = "lightning";
                new_strike.alert_id = strike.id;
                new_strike.alert_info = JSON.stringify(strike);
                this.postWeatherAlert(new_strike);
            }
        })
        .catch(() => {});
}
// TODO: WEBAPP-781
export function getSevereAlerts(projectInfo, AERIS_API_ID, AERIS_API_KEY): Promise<any> {
    const url = `https://api.aerisapi.com/alerts/${projectInfo.lat},${projectInfo.lon}?format=json&client_id=${AERIS_API_ID}&client_secret=${AERIS_API_KEY}`;
    return trackPromise(
        fetch(url, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((response) => {
                for (const alert of response.response) {
                    if (response.length > 0) {
                        const severe_alert = {};
                        severe_alert.affected_jobsites = [projectInfo.unique_token];
                        severe_alert.alert_time = alert.timestamps.issuedISO;
                        severe_alert.alert_type = "severe";
                        severe_alert.alert_id = alert.id;
                        severe_alert.alert_info = JSON.stringify(alert);
                        this.postWeatherAlert(severe_alert);
                    }
                }
            })
            .catch(() => {})
    );
}

export function postWeatherAlert(alert): Promise<object> {
    const url = "/weather_alerts";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                weather_alert: alert,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response;
                }
                throw new Error(response.status);
            })
            .catch((error) => {
                return { message: "Failed to post weather alert: " + error };
            })
    );
}
