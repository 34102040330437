/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `MaterialGroup.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { MaterialGroup } from "./MaterialGroup";

import { Audit } from "./Audit";
import { Material } from "./Material";
import { MaterialGroupsSubmittal } from "./MaterialGroupsSubmittal";
import { Project } from "./Project";
import { Submittal } from "./Submittal";
import { Team } from "./Team";

export abstract class MaterialGroupModel {
    id: number;
    archived: boolean | null;
    materialGroupName: string | null;
    initialized: boolean | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    teamId: number | null;
    status: string | null;
    specNumber: string | null;
    leadTime: number | null;
    requiredOnJobsite: Dayjs | null;
    checkInFrequency: number | null;
    projectId: number | null;
    approvalDate: Dayjs | null;
    submittalNumber: string | null;
    submitDate: Dayjs | null;
    reviewDuration: number | null;
    orderDelay: number | null;
    vendorId: number | null;
    nextOrderBy: Dayjs | null;
    nextRequiredOnJobsite: Dayjs | null;
    audits: Audit[] | undefined;
    project: Project | null | undefined;
    team: Team | null | undefined;
    vendor: Team | null | undefined;
    materials: Material[] | undefined;
    materialGroupsSubmittals: MaterialGroupsSubmittal[] | undefined;
    submittal: Submittal | null | undefined;

     
    constructor(
        id: number,
        archived: boolean | null,
        materialGroupName: string | null,
        initialized: boolean | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        teamId: number | null,
        status: string | null,
        specNumber: string | null,
        leadTime: number | null,
        requiredOnJobsite: Dayjs | null,
        checkInFrequency: number | null,
        projectId: number | null,
        approvalDate: Dayjs | null,
        submittalNumber: string | null,
        submitDate: Dayjs | null,
        reviewDuration: number | null,
        orderDelay: number | null,
        vendorId: number | null,
        nextOrderBy: Dayjs | null,
        nextRequiredOnJobsite: Dayjs | null,
        audits: Audit[] | undefined,
        project: Project | null | undefined,
        team: Team | null | undefined,
        vendor: Team | null | undefined,
        materials: Material[] | undefined,
        materialGroupsSubmittals: MaterialGroupsSubmittal[] | undefined,
        submittal: Submittal | null | undefined
    ) {
        this.id = id;
        this.archived = archived;
        this.materialGroupName = materialGroupName;
        this.initialized = initialized;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.teamId = teamId;
        this.status = status;
        this.specNumber = specNumber;
        this.leadTime = leadTime;
        this.requiredOnJobsite = requiredOnJobsite;
        this.checkInFrequency = checkInFrequency;
        this.projectId = projectId;
        this.approvalDate = approvalDate;
        this.submittalNumber = submittalNumber;
        this.submitDate = submitDate;
        this.reviewDuration = reviewDuration;
        this.orderDelay = orderDelay;
        this.vendorId = vendorId;
        this.nextOrderBy = nextOrderBy;
        this.nextRequiredOnJobsite = nextRequiredOnJobsite;
        this.audits = audits;
        this.project = project;
        this.team = team;
        this.vendor = vendor;
        this.materials = materials;
        this.materialGroupsSubmittals = materialGroupsSubmittals;
        this.submittal = submittal;
    }
     

    static fromApiObject(json: any, timezone: string): MaterialGroup {
        const newObject = new MaterialGroup(
            json["id"],
            json["archived"],
            json["material_group_name"],
            json["initialized"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["team_id"],
            json["status"],
            json["spec_number"],
            json["lead_time"],
            json["required_on_jobsite"] ? dayjs(json["required_on_jobsite"]).tz(timezone) : null,
            json["check_in_frequency"],
            json["project_id"],
            json["approval_date"] ? dayjs(json["approval_date"]).tz(timezone) : null,
            json["submittal_number"],
            json["submit_date"] ? dayjs(json["submit_date"]).tz(timezone) : null,
            json["review_duration"],
            json["order_delay"],
            json["vendor_id"],
            json["next_order_by"] ? dayjs(json["next_order_by"]).tz(timezone) : null,
            json["next_required_on_jobsite"] ? dayjs(json["next_required_on_jobsite"]).tz(timezone) : null,
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["team"] == null ? json["team"] : Team.fromApiObject(json["team"], timezone),
            json["vendor"] == null ? json["vendor"] : Team.fromApiObject(json["vendor"], timezone),
            json["materials"]?.map((item: any) => Material.fromApiObject(item, timezone)),
            json["material_groups_submittals"]?.map((item: any) => MaterialGroupsSubmittal.fromApiObject(item, timezone)),
            json["submittal"] == null ? json["submittal"] : Submittal.fromApiObject(json["submittal"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            archived: this.archived,
            material_group_name: this.materialGroupName,
            initialized: this.initialized,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            team_id: this.teamId,
            status: this.status,
            spec_number: this.specNumber,
            lead_time: this.leadTime,
            required_on_jobsite: this.requiredOnJobsite,
            check_in_frequency: this.checkInFrequency,
            project_id: this.projectId,
            approval_date: this.approvalDate,
            submittal_number: this.submittalNumber,
            submit_date: this.submitDate,
            review_duration: this.reviewDuration,
            order_delay: this.orderDelay,
            vendor_id: this.vendorId,
            next_order_by: this.nextOrderBy,
            next_required_on_jobsite: this.nextRequiredOnJobsite,
            audits: this.audits?.map(item => item.toApiObject()),
            materials: this.materials?.map(item => item.toApiObject()),
            material_groups_submittals: this.materialGroupsSubmittals?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): MaterialGroup {
        const clone = Object.create(this) as MaterialGroup;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
