import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import TeamInfo from "../../teamInfo";

/**
 * Gets the publicly available vendors for a project
 * @param subdomain Subdomain of the owning team of the projkect
 * @param unique_token the unique token string of the project
 * @returns array of TeamInfo objects
 */
export function getvendorsForProject(unique_token: string, subdomain: string): Promise<TeamInfo[]> {
    const url = `${Helper.baseUrlAltSubdomain(subdomain)}/projects/${unique_token}/vendors`;

    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                return response.map((vendor) => new TeamInfo(vendor));
            })
            .catch(() => {
                return [];
            })
    );
}
