/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Delivery.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Delivery } from "./Delivery";

import { Area } from "./Area";
import { Audit } from "./Audit";
import { DeliveriesEquipment } from "./DeliveriesEquipment";
import { DeliveryHasTeam } from "./DeliveryHasTeam";
import { Order } from "./Order";
import { Project } from "./Project";
import { Team } from "./Team";
import { User } from "./User";

export type status = "declined" | "requested_override" | "pending" | "ordered" | "scheduled" | "updated" | "confirmed" | "en_route" | "arrived" | "departed";
export type creationLocation = "doorbell" | "delivery_tab" | "doorbell_unscheduled";

export const statusValues = {"declined":-100,"requested_override":0,"pending":50,"ordered":75,"scheduled":100,"updated":200,"confirmed":300,"en_route":400,"arrived":500,"departed":600};
export const creationLocationValues = {"doorbell":0,"delivery_tab":1,"doorbell_unscheduled":2};

export abstract class DeliveryModel {
    id: number;
    title: string | null;
    description: string | null;
    startDateTime: Dayjs | null;
    endDateTime: Dayjs | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    dropOffAreaUuid: string | null;
    deliveryContactInfo: string | null;
    archived: boolean | null;
    uniqueToken: string;
    arrivalTime: Dayjs | null;
    departureTime: Dayjs | null;
    eta: Dayjs | null;
    status: status;
    waypointUuid: string | null;
    requestedOverride: boolean | null;
    creationLocation: creationLocation;
    vehicleType: string | null;
    idleTime: number | null;
    travelDistance: number | null;
    etaSelected: number | null;
    createdByUserId: number | null;
    integrationLogId: number | null;
    deliveryRoute: string | null;
    driverEstimatedTravelTime: number | null;
    calendarEventChannelActive: boolean | null;
    projectId: number | null;
    vendorId: number | null;
    vendorUserId: number | null;
    vendorDirections: any | null;
    finalApproved: boolean | null;
    audits: Audit[] | undefined;
    project: Project | null | undefined;
    dropOffArea: Area | null | undefined;
    waypoint: Area | null | undefined;
    createdBy: User | null | undefined;
    vendor: Team | null | undefined;
    vendorUser: User | null | undefined;
    deliveryHasTeams: DeliveryHasTeam[] | undefined;
    orders: Order[] | undefined;
    deliveriesEquipment: DeliveriesEquipment[] | undefined;

     
    constructor(
        id: number,
        title: string | null,
        description: string | null,
        startDateTime: Dayjs | null,
        endDateTime: Dayjs | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        dropOffAreaUuid: string | null,
        deliveryContactInfo: string | null,
        archived: boolean | null,
        uniqueToken: string,
        arrivalTime: Dayjs | null,
        departureTime: Dayjs | null,
        eta: Dayjs | null,
        status: status,
        waypointUuid: string | null,
        requestedOverride: boolean | null,
        creationLocation: creationLocation,
        vehicleType: string | null,
        idleTime: number | null,
        travelDistance: number | null,
        etaSelected: number | null,
        createdByUserId: number | null,
        integrationLogId: number | null,
        deliveryRoute: string | null,
        driverEstimatedTravelTime: number | null,
        calendarEventChannelActive: boolean | null,
        projectId: number | null,
        vendorId: number | null,
        vendorUserId: number | null,
        vendorDirections: any | null,
        finalApproved: boolean | null,
        audits: Audit[] | undefined,
        project: Project | null | undefined,
        dropOffArea: Area | null | undefined,
        waypoint: Area | null | undefined,
        createdBy: User | null | undefined,
        vendor: Team | null | undefined,
        vendorUser: User | null | undefined,
        deliveryHasTeams: DeliveryHasTeam[] | undefined,
        orders: Order[] | undefined,
        deliveriesEquipment: DeliveriesEquipment[] | undefined
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.dropOffAreaUuid = dropOffAreaUuid;
        this.deliveryContactInfo = deliveryContactInfo;
        this.archived = archived;
        this.uniqueToken = uniqueToken;
        this.arrivalTime = arrivalTime;
        this.departureTime = departureTime;
        this.eta = eta;
        this.status = status;
        this.waypointUuid = waypointUuid;
        this.requestedOverride = requestedOverride;
        this.creationLocation = creationLocation;
        this.vehicleType = vehicleType;
        this.idleTime = idleTime;
        this.travelDistance = travelDistance;
        this.etaSelected = etaSelected;
        this.createdByUserId = createdByUserId;
        this.integrationLogId = integrationLogId;
        this.deliveryRoute = deliveryRoute;
        this.driverEstimatedTravelTime = driverEstimatedTravelTime;
        this.calendarEventChannelActive = calendarEventChannelActive;
        this.projectId = projectId;
        this.vendorId = vendorId;
        this.vendorUserId = vendorUserId;
        this.vendorDirections = vendorDirections;
        this.finalApproved = finalApproved;
        this.audits = audits;
        this.project = project;
        this.dropOffArea = dropOffArea;
        this.waypoint = waypoint;
        this.createdBy = createdBy;
        this.vendor = vendor;
        this.vendorUser = vendorUser;
        this.deliveryHasTeams = deliveryHasTeams;
        this.orders = orders;
        this.deliveriesEquipment = deliveriesEquipment;
    }
     

    static fromApiObject(json: any, timezone: string): Delivery {
        const newObject = new Delivery(
            json["id"],
            json["title"],
            json["description"],
            json["start_date_time"] ? dayjs(json["start_date_time"]).tz(timezone) : null,
            json["end_date_time"] ? dayjs(json["end_date_time"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["drop_off_area_uuid"],
            json["delivery_contact_info"],
            json["archived"],
            json["unique_token"],
            json["arrival_time"] ? dayjs(json["arrival_time"]).tz(timezone) : null,
            json["departure_time"] ? dayjs(json["departure_time"]).tz(timezone) : null,
            json["eta"] ? dayjs(json["eta"]).tz(timezone) : null,
            json["status"],
            json["waypoint_uuid"],
            json["requested_override"],
            json["creation_location"],
            json["vehicle_type"],
            json["idle_time"],
            json["travel_distance"],
            json["eta_selected"],
            json["created_by_user_id"],
            json["integration_log_id"],
            json["delivery_route"],
            json["driver_estimated_travel_time"],
            json["calendar_event_channel_active"],
            json["project_id"],
            json["vendor_id"],
            json["vendor_user_id"],
            json["vendor_directions"],
            json["final_approved"],
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["drop_off_area"] == null ? json["drop_off_area"] : Area.fromApiObject(json["drop_off_area"], timezone),
            json["waypoint"] == null ? json["waypoint"] : Area.fromApiObject(json["waypoint"], timezone),
            json["created_by"] == null ? json["created_by"] : User.fromApiObject(json["created_by"], timezone),
            json["vendor"] == null ? json["vendor"] : Team.fromApiObject(json["vendor"], timezone),
            json["vendor_user"] == null ? json["vendor_user"] : User.fromApiObject(json["vendor_user"], timezone),
            json["delivery_has_teams"]?.map((item: any) => DeliveryHasTeam.fromApiObject(item, timezone)),
            json["orders"]?.map((item: any) => Order.fromApiObject(item, timezone)),
            json["deliveries_equipment"]?.map((item: any) => DeliveriesEquipment.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            start_date_time: this.startDateTime,
            end_date_time: this.endDateTime,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            drop_off_area_uuid: this.dropOffAreaUuid,
            delivery_contact_info: this.deliveryContactInfo,
            archived: this.archived,
            unique_token: this.uniqueToken,
            arrival_time: this.arrivalTime,
            departure_time: this.departureTime,
            eta: this.eta,
            status: this.status,
            waypoint_uuid: this.waypointUuid,
            requested_override: this.requestedOverride,
            creation_location: this.creationLocation,
            vehicle_type: this.vehicleType,
            idle_time: this.idleTime,
            travel_distance: this.travelDistance,
            eta_selected: this.etaSelected,
            created_by_user_id: this.createdByUserId,
            integration_log_id: this.integrationLogId,
            delivery_route: this.deliveryRoute,
            driver_estimated_travel_time: this.driverEstimatedTravelTime,
            calendar_event_channel_active: this.calendarEventChannelActive,
            project_id: this.projectId,
            vendor_id: this.vendorId,
            vendor_user_id: this.vendorUserId,
            vendor_directions: this.vendorDirections,
            final_approved: this.finalApproved,
            audits: this.audits?.map(item => item.toApiObject()),
            delivery_has_teams_attributes: this.deliveryHasTeams?.map(item => item.toApiObject()),
            orders: this.orders?.map(item => item.toApiObject()),
            deliveries_equipment_attributes: this.deliveriesEquipment?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Delivery {
        const clone = Object.create(this) as Delivery;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
