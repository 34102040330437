/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `FireTv.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { FireTv } from "./FireTv";

import { User } from "./User";

export abstract class FireTvModel {
    id: number;
    tvUuid: string | null;
    accessToken: string | null;
    connectCode: string | null;
    userId: number | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    connectCodeExpiresAt: Dayjs | null;
    user: User | null | undefined;

     
    constructor(
        id: number,
        tvUuid: string | null,
        accessToken: string | null,
        connectCode: string | null,
        userId: number | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        connectCodeExpiresAt: Dayjs | null,
        user: User | null | undefined
    ) {
        this.id = id;
        this.tvUuid = tvUuid;
        this.accessToken = accessToken;
        this.connectCode = connectCode;
        this.userId = userId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.connectCodeExpiresAt = connectCodeExpiresAt;
        this.user = user;
    }
     

    static fromApiObject(json: any, timezone: string): FireTv {
        const newObject = new FireTv(
            json["id"],
            json["tv_uuid"],
            json["access_token"],
            json["connect_code"],
            json["user_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["connect_code_expires_at"] ? dayjs(json["connect_code_expires_at"]).tz(timezone) : null,
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            tv_uuid: this.tvUuid,
            access_token: this.accessToken,
            connect_code: this.connectCode,
            user_id: this.userId,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            connect_code_expires_at: this.connectCodeExpiresAt
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): FireTv {
        const clone = Object.create(this) as FireTv;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
