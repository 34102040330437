import Helper from "../../Helper";
import { Equipment } from "../../models/Equipment";

export function getEquipmentForProject(
    projectToken: string,
    subdomain: string = "",
    timezone: string
): Promise<Equipment[]> {
    let url = "/projects/" + projectToken + "/equipment";

    if (subdomain && subdomain.length > 0) {
        url = Helper.baseUrlAltSubdomain(subdomain) + url;
    }

    return fetch(url, {
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error("Failed to get project equipments");
        })
        .then((res) => {
            return res.map((eq) => Equipment.fromApiObject(eq, timezone));
        })
        .catch(() => {
            return new Array<Equipment>();
        });
}
