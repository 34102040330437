import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { FilterInfo, Material, MaterialGroup } from "../Materials/materialTypes";

export function createMaterialForProject(
    team_subdomain: string,
    project_token: string,
    material: Material,
    material_group_id: number | null
): Promise<Material | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials";

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ material: material.toApiObject(), material_group_id: material_group_id }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return Material.fromApiObject(data);
            })
            .catch(() => {
                return null;
            })
    );
}

export function updateMaterialForProject(
    team_subdomain: string,
    project_token: string,
    material: Material
): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials/" +
        material.id;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ material: material.toApiObject() }),
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function deleteMaterialForProject(
    team_subdomain: string,
    project_token: string,
    material: Material
): Promise<any[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials/" +
        material.id;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getMaterialGroupsForProject(
    team_subdomain: string,
    project_token: string,
    get_archived?: boolean
): Promise<MaterialGroup[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups?archived=" +
        (get_archived ? "true" : "false");

    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                const material_groups = new Array<MaterialGroup>();

                for (const material_group of response) {
                    material_groups.push(MaterialGroup.fromApiObject(material_group));
                }

                return material_groups;
            })
            .catch(() => {
                return [];
            })
    );
}

export function getMaterialGroupsForProjectFiltered(
    team_subdomain: string,
    project_token: string,
    filter_info: FilterInfo,
    material_group_ids?: number[]
): Promise<MaterialGroup[]> {
    const base_url = new URL(
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
            "/projects/" +
            project_token +
            "/material_groups?archived=false"
    );

    if (material_group_ids) {
        base_url.searchParams.append("material_group_ids", material_group_ids.toString());
    }

    const url = filter_info.appendedToUrl(base_url);

    return trackPromise(
        fetch(url, {
            method: "get",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                const material_groups = new Array<MaterialGroup>();

                for (const material_group of response) {
                    material_groups.push(MaterialGroup.fromApiObject(material_group));
                }

                return material_groups;
            })
            .catch(() => {
                return [];
            })
    );
}

export function createMaterialGroupForProject(
    team_subdomain: string,
    project_token: string,
    material_group: MaterialGroup
) {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups";

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ material_group: material_group.toApiObject() }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return MaterialGroup.fromApiObject(data);
            })
            .catch(() => {
                return null;
            })
    );
}

export function updateMaterialGroupForProject(
    team_subdomain: string,
    project_token: string,
    material_group: MaterialGroup
) {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups/" +
        material_group.id;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                material_group: material_group.toApiObject(),
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return MaterialGroup.fromApiObject(data);
            })
            .catch(() => {
                return null;
            })
    );
}

export function deleteMaterialGroupForProject(
    team_subdomain: string,
    project_token: string,
    material_group: MaterialGroup
): Promise<any[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups/" +
        material_group.id;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}
export function sendProcurementActionItemsEmail(project) {
    const url =
        Helper.baseUrlAltSubdomain(project.claimed_by_team_subdomain) +
        "/projects/" +
        project.unique_token +
        "/action_items_email_send";

    return trackPromise(
        fetch(url, {
            method: "post",
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}
