/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `AreaHasEquipment.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { AreaHasEquipment } from "./AreaHasEquipment";

import { Area } from "./Area";
import { Equipment } from "./Equipment";

export abstract class AreaHasEquipmentModel {
    id: number;
    areaId: number;
    equipmentId: number;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    area: Area | null | undefined;
    equipment: Equipment | null | undefined;

     
    constructor(
        id: number,
        areaId: number,
        equipmentId: number,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        area: Area | null | undefined,
        equipment: Equipment | null | undefined
    ) {
        this.id = id;
        this.areaId = areaId;
        this.equipmentId = equipmentId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.area = area;
        this.equipment = equipment;
    }
     

    static fromApiObject(json: any, timezone: string): AreaHasEquipment {
        const newObject = new AreaHasEquipment(
            json["id"],
            json["area_id"],
            json["equipment_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["area"] == null ? json["area"] : Area.fromApiObject(json["area"], timezone),
            json["equipment"] == null ? json["equipment"] : Equipment.fromApiObject(json["equipment"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            area_id: this.areaId,
            equipment_id: this.equipmentId,
            created_at: this.createdAt,
            updated_at: this.updatedAt
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): AreaHasEquipment {
        const clone = Object.create(this) as AreaHasEquipment;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
