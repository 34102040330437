import { sub } from "date-fns";
import { Submittal } from "../Submittal/submittalTypes";
import { MaterialGroup } from "./materialGroup";
import { Order } from "./order";

export class Material {
    id: number;
    project_id: number;
    created_at: Date;
    updated_at: Date;
    material_name: string;
    unit_of_measure: string;
    archived: boolean;
    material_group_id: number;
    lead_time: number | null;
    required_on_jobsite: Date | null;
    check_in_frequency: number | null;
    approval_date: Date | null;
    submit_date: Date | null;
    review_duration: number;
    order_delay: number;
    submittal_number: string;
    spec_number: string;

    orders: Order[];
    submittals: Submittal[];
    material_group: MaterialGroup; // Does not come back from the api but is often set in rendering

    static fromApiObject(material: any): Material {
        const new_material = new Material();
        new_material.id = material.id;
        new_material.project_id = material.project_id;
        new_material.created_at = new Date(material.created_at);
        new_material.updated_at = new Date(material.updated_at);
        new_material.material_name = material.material_name;
        new_material.unit_of_measure = material.unit_of_measure;
        new_material.archived = material.archived;
        new_material.material_group_id = material.material_group_id;
        new_material.check_in_frequency = material.check_in_frequency;
        new_material.lead_time = material.lead_time;
        new_material.required_on_jobsite = material.required_on_jobsite ? new Date(material.required_on_jobsite) : null;
        new_material.approval_date = material.approval_date ? new Date(material.approval_date) : null;
        new_material.submit_date = material.submit_date ? new Date(material.submit_date) : null;
        new_material.review_duration = material.review_duration;
        new_material.order_delay = material.order_delay;
        new_material.submittal_number = material.submittal_number;
        new_material.spec_number = material.spec_number;

        new_material.orders = material.orders?.map((order) => Order.fromApiObject(order)) || [];
        new_material.submittals = material.submittals?.map((submittal) => Submittal.fromApiObject(submittal)) || [];

        return new_material;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_name: this.material_name,
            unit_of_measure: this.unit_of_measure,
            archived: this.archived,
            material_group_id: this.material_group_id,
            check_in_frequency: this.check_in_frequency,
            lead_time: this.lead_time,
            required_on_jobsite: this.required_on_jobsite,
            approval_date: this.approval_date,
            submit_date: this.submit_date,
            review_duration: this.review_duration,
            order_delay: this.order_delay,
            submittal_number: this.submittal_number,
            spec_number: this.spec_number,
            submittal_ids: this.submittals.map((submittal) => submittal.id),
        };
    }

    // Get the orders that are in need of action, aka. need user input to make sure they are on track
    ordersInNeedOfAction(): Order[] {
        return this.orders.filter((order) => !order.archived && order.cushion && order.cushion < 0);
    }

    submitBy(): Date | null {
        const approveBy = this.approveBy();

        if (!approveBy) {
            return null;
        }

        return sub(approveBy, { days: this.review_duration });
    }

    approveBy(): Date | null {
        const orderBy = this.nextOrderBy();

        if (!orderBy) {
            return null;
        }

        return sub(orderBy, { days: this.order_delay });
    }

    nextOrderBy(): Date | null {
        const unorderedOrders = this.orders.filter((order) => !order.ordered && order.order_by && !order.archived);

        if (unorderedOrders.length > 0) {
            const sortedOrders = unorderedOrders.sort(
                // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a calculated order by
                (a, b) => a.order_by?.getTime() - b.order_by?.getTime()
            );

            // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a calculated order by
            const orderBy: Date = sortedOrders[0].order_by;

            return orderBy;
        }

        return null;
    }

    nextROJ(): Date | null {
        const noDeliveryScheduledOrders = this.orders.filter(
            (order) => order.required_on_jobsite && !order.delivery && !order.archived
        );

        if (noDeliveryScheduledOrders.length > 0) {
            const sortedOrders = noDeliveryScheduledOrders.sort(
                // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a required_on_jobsite
                (a, b) => a.required_on_jobsite?.getTime() - b.required_on_jobsite?.getTime()
            );

            // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a required_on_jobsite
            const dateNeeded: Date = sortedOrders[0].required_on_jobsite;

            return dateNeeded;
        }

        return null;
    }
}
