/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Geotiff.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Geotiff } from "./Geotiff";

import { Layer } from "./Layer";

export abstract class GeotiffModel {
    id: number;
    layerId: number;
    geotiffName: string | null;
    geotiffId: string | null;
    complete: boolean | null;
    progress: number | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    layer: Layer | null | undefined;

     
    constructor(
        id: number,
        layerId: number,
        geotiffName: string | null,
        geotiffId: string | null,
        complete: boolean | null,
        progress: number | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        layer: Layer | null | undefined
    ) {
        this.id = id;
        this.layerId = layerId;
        this.geotiffName = geotiffName;
        this.geotiffId = geotiffId;
        this.complete = complete;
        this.progress = progress;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.layer = layer;
    }
     

    static fromApiObject(json: any, timezone: string): Geotiff {
        const newObject = new Geotiff(
            json["id"],
            json["layer_id"],
            json["geotiff_name"],
            json["geotiff_id"],
            json["complete"],
            json["progress"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["layer"] == null ? json["layer"] : Layer.fromApiObject(json["layer"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            layer_id: this.layerId,
            geotiff_name: this.geotiffName,
            geotiff_id: this.geotiffId,
            complete: this.complete,
            progress: this.progress,
            created_at: this.createdAt,
            updated_at: this.updatedAt
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Geotiff {
        const clone = Object.create(this) as Geotiff;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
