import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";

export function revokeInviteToTeam(
    user_id: number,
    subdomain: string = "",
    team_id: number | null = null,
    project_token: string = ""
): Promise<any> {
    let url = "/teams/revoke_invite";

    if (subdomain !== "") {
        url = Helper.baseUrlAltSubdomain(subdomain) + url;
    }

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                trade_id: team_id,
                project_id: project_token,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .catch(() => {
                return null;
            })
    );
}

export function resendInviteToTeam(
    user_id: number,
    subdomain: string = "",
    team_id: number | null = null,
    project_token: string = ""
): Promise<any> {
    let url = "/teams/invite/resend";

    if (subdomain !== "") {
        url = Helper.baseUrlAltSubdomain(subdomain) + "/teams/invite/resend";
    }

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                trade_id: team_id,
                project_id: project_token,
            }),
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function acceptInviteToTeam(subdomain: string = "", team_id: number): Promise<any> {
    let url = "/teams/invite/accept";

    if (subdomain && subdomain.length > 0) {
        url = Helper.baseUrlAltSubdomain(subdomain) + url;
    }

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                team_id: team_id,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .catch(() => {
                return null;
            })
    );
}
