/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Project.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Project } from "./Project";

import { Activity } from "./Activity";
import { Audit } from "./Audit";
import { Delivery } from "./Delivery";
import { Equipment } from "./Equipment";
import { JobsiteExceptionDay } from "./JobsiteExceptionDay";
import { Layer } from "./Layer";
import { Material } from "./Material";
import { MaterialGroup } from "./MaterialGroup";
import { Message } from "./Message";
import { NotificationList } from "./NotificationList";
import { Order } from "./Order";
import { OrderExport } from "./OrderExport";
import { Permission } from "./Permission";
import { ProjectsWeatherAlert } from "./ProjectsWeatherAlert";
import { Submittal } from "./Submittal";
import { Team } from "./Team";
import { Value } from "./Value";
import { VendorLink } from "./VendorLink";

export type integrationSource = "user_upload" | "procore" | "procore_sandbox" | "autodesk_construction" | "cmic" | "primavera_p6" | "microsoft_project" | "asta_power_project" | "faker";
export type leadTimesDefaultActivityDependency = "start" | "finish";

export const integrationSourceValues = {"user_upload":0,"procore":1,"procore_sandbox":2,"autodesk_construction":3,"cmic":4,"primavera_p6":5,"microsoft_project":6,"asta_power_project":7,"faker":8};
export const leadTimesDefaultActivityDependencyValues = {"start":0,"finish":1};

export abstract class ProjectModel {
    id: number;
    projectName: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    lat: number | null;
    lon: number | null;
    listed: boolean | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    contactsList: string | null;
    latOverride: number | null;
    lonOverride: number | null;
    useOverride: boolean | null;
    buildCentralId: number | null;
    projectHasBeenModified: boolean | null;
    owningTeamId: number | null;
    uniqueToken: string | null;
    deliveryHoursStart: Dayjs | null;
    deliveryHoursEnd: Dayjs | null;
    useAdvancedDeliveryLogic: boolean | null;
    useAdvancedDeliveryLogicMapMarker: boolean | null;
    useAdvancedDeliveryLogicEquipment: boolean | null;
    useAdvancedDeliveryLogicTeams: boolean | null;
    autoApproveGuestToken: string | null;
    deliveryCreatedApprovalRequireTeams: boolean | null;
    deliveryScheduledApprovalRequireTeams: boolean | null;
    deliveryApprovalRequireGc: boolean | null;
    autoApproveToken: string | null;
    shareEta: boolean | null;
    shareDeliveryVehicle: boolean | null;
    deliveryLogging: boolean | null;
    enableVendorScheduling: boolean | null;
    useAdvancedDeliveryLogicTrades: boolean | null;
    timezone: string | null;
    integrationDailyLogDeliveries: boolean | null;
    integrationSubmittalsDeliveries: boolean | null;
    integrationImportMembers: boolean | null;
    workWeekDays: number | null;
    observedFederalHolidays: number | null;
    useAdvancedDeliveryLogicExceptionDays: boolean | null;
    enableLightningAlerts: boolean | null;
    lightningAlertDistance: number | null;
    lightningAllClearDuration: number | null;
    enableWeatherSevere: boolean | null;
    enableWeatherThreats: boolean | null;
    lightningAlertsChannel: number | null;
    weatherThreatsChannel: number | null;
    weatherSevereChannel: number | null;
    minDeliverySchedule: number | null;
    maxDeliverySchedule: number | null;
    lastLightningStrike: Dayjs | null;
    lightningAllClear: boolean | null;
    deliveryHoursAllDay: boolean;
    gcCanApproveTradeDeliveries: boolean | null;
    requireVendorInfoDeliveries: boolean | null;
    useLeadTimes: boolean | null;
    actionItemsEmailDay: number | null;
    actionItemsEmailTime: Dayjs | null;
    detail: string | null;
    leadTimesDefaultLeadTime: number | null;
    leadTimesDefaultCheckInFrequency: number | null;
    leadTimesReviewDuration: number | null;
    leadTimesOrderDelay: number | null;
    leadTimesRequiredOnJobsiteRequireGc: boolean | null;
    commandCenterSettings: any | null;
    archived: boolean | null;
    integrationSourceProjectId: string | null;
    integrationAccessInfo: any | null;
    integrationSource: integrationSource;
    integrationSourceCompanyId: string | null;
    procurementSyncTime: Dayjs | null;
    deliveryApprovalRequireFinal: boolean | null;
    integrationLastSyncedSubmittals: Dayjs | null;
    integrationLastSyncedActivities: Dayjs | null;
    integrationUserUploadActivitiesFilename: string | null;
    procurementSyncDoSubmittals: boolean;
    procurementSyncDoActivities: boolean;
    notificationListsTeams: boolean;
    notificationListsAreas: boolean;
    notificationListsEquipments: boolean;
    useAdvancedDeliveryLogicPending: boolean;
    overviewEmailDay: number | null;
    overviewEmailTime: Dayjs | null;
    useDeliveries: boolean;
    leadTimesDefaultActivityDependency: leadTimesDefaultActivityDependency;
    audits: Audit[] | undefined;
    layers: Layer[] | undefined;
    materialGroups: MaterialGroup[] | undefined;
    materials: Material[] | undefined;
    orders: Order[] | undefined;
    permissions: Permission[] | undefined;
    equipment: Equipment[] | undefined;
    deliveries: Delivery[] | undefined;
    values: Value[] | undefined;
    submittals: Submittal[] | undefined;
    jobsiteExceptionDays: JobsiteExceptionDay[] | undefined;
    messages: Message[] | undefined;
    projectsWeatherAlerts: ProjectsWeatherAlert[] | undefined;
    orderExports: OrderExport[] | undefined;
    activities: Activity[] | undefined;
    notificationLists: NotificationList[] | undefined;
    vendorLinks: VendorLink[] | undefined;
    owningTeam: Team | null | undefined;

    /* eslint-disable max-statements */
    constructor(
        id: number,
        projectName: string | null,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string | null,
        zipCode: string | null,
        lat: number | null,
        lon: number | null,
        listed: boolean | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        contactsList: string | null,
        latOverride: number | null,
        lonOverride: number | null,
        useOverride: boolean | null,
        buildCentralId: number | null,
        projectHasBeenModified: boolean | null,
        owningTeamId: number | null,
        uniqueToken: string | null,
        deliveryHoursStart: Dayjs | null,
        deliveryHoursEnd: Dayjs | null,
        useAdvancedDeliveryLogic: boolean | null,
        useAdvancedDeliveryLogicMapMarker: boolean | null,
        useAdvancedDeliveryLogicEquipment: boolean | null,
        useAdvancedDeliveryLogicTeams: boolean | null,
        autoApproveGuestToken: string | null,
        deliveryCreatedApprovalRequireTeams: boolean | null,
        deliveryScheduledApprovalRequireTeams: boolean | null,
        deliveryApprovalRequireGc: boolean | null,
        autoApproveToken: string | null,
        shareEta: boolean | null,
        shareDeliveryVehicle: boolean | null,
        deliveryLogging: boolean | null,
        enableVendorScheduling: boolean | null,
        useAdvancedDeliveryLogicTrades: boolean | null,
        timezone: string | null,
        integrationDailyLogDeliveries: boolean | null,
        integrationSubmittalsDeliveries: boolean | null,
        integrationImportMembers: boolean | null,
        workWeekDays: number | null,
        observedFederalHolidays: number | null,
        useAdvancedDeliveryLogicExceptionDays: boolean | null,
        enableLightningAlerts: boolean | null,
        lightningAlertDistance: number | null,
        lightningAllClearDuration: number | null,
        enableWeatherSevere: boolean | null,
        enableWeatherThreats: boolean | null,
        lightningAlertsChannel: number | null,
        weatherThreatsChannel: number | null,
        weatherSevereChannel: number | null,
        minDeliverySchedule: number | null,
        maxDeliverySchedule: number | null,
        lastLightningStrike: Dayjs | null,
        lightningAllClear: boolean | null,
        deliveryHoursAllDay: boolean,
        gcCanApproveTradeDeliveries: boolean | null,
        requireVendorInfoDeliveries: boolean | null,
        useLeadTimes: boolean | null,
        actionItemsEmailDay: number | null,
        actionItemsEmailTime: Dayjs | null,
        detail: string | null,
        leadTimesDefaultLeadTime: number | null,
        leadTimesDefaultCheckInFrequency: number | null,
        leadTimesReviewDuration: number | null,
        leadTimesOrderDelay: number | null,
        leadTimesRequiredOnJobsiteRequireGc: boolean | null,
        commandCenterSettings: any | null,
        archived: boolean | null,
        integrationSourceProjectId: string | null,
        integrationAccessInfo: any | null,
        integrationSource: integrationSource,
        integrationSourceCompanyId: string | null,
        procurementSyncTime: Dayjs | null,
        deliveryApprovalRequireFinal: boolean | null,
        integrationLastSyncedSubmittals: Dayjs | null,
        integrationLastSyncedActivities: Dayjs | null,
        integrationUserUploadActivitiesFilename: string | null,
        procurementSyncDoSubmittals: boolean,
        procurementSyncDoActivities: boolean,
        notificationListsTeams: boolean,
        notificationListsAreas: boolean,
        notificationListsEquipments: boolean,
        useAdvancedDeliveryLogicPending: boolean,
        overviewEmailDay: number | null,
        overviewEmailTime: Dayjs | null,
        useDeliveries: boolean,
        leadTimesDefaultActivityDependency: leadTimesDefaultActivityDependency,
        audits: Audit[] | undefined,
        layers: Layer[] | undefined,
        materialGroups: MaterialGroup[] | undefined,
        materials: Material[] | undefined,
        orders: Order[] | undefined,
        permissions: Permission[] | undefined,
        equipment: Equipment[] | undefined,
        deliveries: Delivery[] | undefined,
        values: Value[] | undefined,
        submittals: Submittal[] | undefined,
        jobsiteExceptionDays: JobsiteExceptionDay[] | undefined,
        messages: Message[] | undefined,
        projectsWeatherAlerts: ProjectsWeatherAlert[] | undefined,
        orderExports: OrderExport[] | undefined,
        activities: Activity[] | undefined,
        notificationLists: NotificationList[] | undefined,
        vendorLinks: VendorLink[] | undefined,
        owningTeam: Team | null | undefined
    ) {
        this.id = id;
        this.projectName = projectName;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.lat = lat;
        this.lon = lon;
        this.listed = listed;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.contactsList = contactsList;
        this.latOverride = latOverride;
        this.lonOverride = lonOverride;
        this.useOverride = useOverride;
        this.buildCentralId = buildCentralId;
        this.projectHasBeenModified = projectHasBeenModified;
        this.owningTeamId = owningTeamId;
        this.uniqueToken = uniqueToken;
        this.deliveryHoursStart = deliveryHoursStart;
        this.deliveryHoursEnd = deliveryHoursEnd;
        this.useAdvancedDeliveryLogic = useAdvancedDeliveryLogic;
        this.useAdvancedDeliveryLogicMapMarker = useAdvancedDeliveryLogicMapMarker;
        this.useAdvancedDeliveryLogicEquipment = useAdvancedDeliveryLogicEquipment;
        this.useAdvancedDeliveryLogicTeams = useAdvancedDeliveryLogicTeams;
        this.autoApproveGuestToken = autoApproveGuestToken;
        this.deliveryCreatedApprovalRequireTeams = deliveryCreatedApprovalRequireTeams;
        this.deliveryScheduledApprovalRequireTeams = deliveryScheduledApprovalRequireTeams;
        this.deliveryApprovalRequireGc = deliveryApprovalRequireGc;
        this.autoApproveToken = autoApproveToken;
        this.shareEta = shareEta;
        this.shareDeliveryVehicle = shareDeliveryVehicle;
        this.deliveryLogging = deliveryLogging;
        this.enableVendorScheduling = enableVendorScheduling;
        this.useAdvancedDeliveryLogicTrades = useAdvancedDeliveryLogicTrades;
        this.timezone = timezone;
        this.integrationDailyLogDeliveries = integrationDailyLogDeliveries;
        this.integrationSubmittalsDeliveries = integrationSubmittalsDeliveries;
        this.integrationImportMembers = integrationImportMembers;
        this.workWeekDays = workWeekDays;
        this.observedFederalHolidays = observedFederalHolidays;
        this.useAdvancedDeliveryLogicExceptionDays = useAdvancedDeliveryLogicExceptionDays;
        this.enableLightningAlerts = enableLightningAlerts;
        this.lightningAlertDistance = lightningAlertDistance;
        this.lightningAllClearDuration = lightningAllClearDuration;
        this.enableWeatherSevere = enableWeatherSevere;
        this.enableWeatherThreats = enableWeatherThreats;
        this.lightningAlertsChannel = lightningAlertsChannel;
        this.weatherThreatsChannel = weatherThreatsChannel;
        this.weatherSevereChannel = weatherSevereChannel;
        this.minDeliverySchedule = minDeliverySchedule;
        this.maxDeliverySchedule = maxDeliverySchedule;
        this.lastLightningStrike = lastLightningStrike;
        this.lightningAllClear = lightningAllClear;
        this.deliveryHoursAllDay = deliveryHoursAllDay;
        this.gcCanApproveTradeDeliveries = gcCanApproveTradeDeliveries;
        this.requireVendorInfoDeliveries = requireVendorInfoDeliveries;
        this.useLeadTimes = useLeadTimes;
        this.actionItemsEmailDay = actionItemsEmailDay;
        this.actionItemsEmailTime = actionItemsEmailTime;
        this.detail = detail;
        this.leadTimesDefaultLeadTime = leadTimesDefaultLeadTime;
        this.leadTimesDefaultCheckInFrequency = leadTimesDefaultCheckInFrequency;
        this.leadTimesReviewDuration = leadTimesReviewDuration;
        this.leadTimesOrderDelay = leadTimesOrderDelay;
        this.leadTimesRequiredOnJobsiteRequireGc = leadTimesRequiredOnJobsiteRequireGc;
        this.commandCenterSettings = commandCenterSettings;
        this.archived = archived;
        this.integrationSourceProjectId = integrationSourceProjectId;
        this.integrationAccessInfo = integrationAccessInfo;
        this.integrationSource = integrationSource;
        this.integrationSourceCompanyId = integrationSourceCompanyId;
        this.procurementSyncTime = procurementSyncTime;
        this.deliveryApprovalRequireFinal = deliveryApprovalRequireFinal;
        this.integrationLastSyncedSubmittals = integrationLastSyncedSubmittals;
        this.integrationLastSyncedActivities = integrationLastSyncedActivities;
        this.integrationUserUploadActivitiesFilename = integrationUserUploadActivitiesFilename;
        this.procurementSyncDoSubmittals = procurementSyncDoSubmittals;
        this.procurementSyncDoActivities = procurementSyncDoActivities;
        this.notificationListsTeams = notificationListsTeams;
        this.notificationListsAreas = notificationListsAreas;
        this.notificationListsEquipments = notificationListsEquipments;
        this.useAdvancedDeliveryLogicPending = useAdvancedDeliveryLogicPending;
        this.overviewEmailDay = overviewEmailDay;
        this.overviewEmailTime = overviewEmailTime;
        this.useDeliveries = useDeliveries;
        this.leadTimesDefaultActivityDependency = leadTimesDefaultActivityDependency;
        this.audits = audits;
        this.layers = layers;
        this.materialGroups = materialGroups;
        this.materials = materials;
        this.orders = orders;
        this.permissions = permissions;
        this.equipment = equipment;
        this.deliveries = deliveries;
        this.values = values;
        this.submittals = submittals;
        this.jobsiteExceptionDays = jobsiteExceptionDays;
        this.messages = messages;
        this.projectsWeatherAlerts = projectsWeatherAlerts;
        this.orderExports = orderExports;
        this.activities = activities;
        this.notificationLists = notificationLists;
        this.vendorLinks = vendorLinks;
        this.owningTeam = owningTeam;
    }
    /* eslint-enable max-statements */

    static fromApiObject(json: any, timezone: string): Project {
        const newObject = new Project(
            json["id"],
            json["project_name"],
            json["address_1"],
            json["address_2"],
            json["city"],
            json["state"],
            json["zip_code"],
            json["lat"],
            json["lon"],
            json["listed"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["contacts_list"],
            json["lat_override"],
            json["lon_override"],
            json["use_override"],
            json["build_central_id"],
            json["project_has_been_modified"],
            json["owning_team_id"],
            json["unique_token"],
            json["delivery_hours_start"] ? dayjs(json["delivery_hours_start"]).tz(timezone) : null,
            json["delivery_hours_end"] ? dayjs(json["delivery_hours_end"]).tz(timezone) : null,
            json["use_advanced_delivery_logic"],
            json["use_advanced_delivery_logic_map_marker"],
            json["use_advanced_delivery_logic_equipment"],
            json["use_advanced_delivery_logic_teams"],
            json["auto_approve_guest_token"],
            json["delivery_created_approval_require_teams"],
            json["delivery_scheduled_approval_require_teams"],
            json["delivery_approval_require_gc"],
            json["auto_approve_token"],
            json["share_eta"],
            json["share_delivery_vehicle"],
            json["delivery_logging"],
            json["enable_vendor_scheduling"],
            json["use_advanced_delivery_logic_trades"],
            json["timezone"],
            json["integration_daily_log_deliveries"],
            json["integration_submittals_deliveries"],
            json["integration_import_members"],
            json["work_week_days"],
            json["observed_federal_holidays"],
            json["use_advanced_delivery_logic_exception_days"],
            json["enable_lightning_alerts"],
            json["lightning_alert_distance"],
            json["lightning_all_clear_duration"],
            json["enable_weather_severe"],
            json["enable_weather_threats"],
            json["lightning_alerts_channel"],
            json["weather_threats_channel"],
            json["weather_severe_channel"],
            json["min_delivery_schedule"],
            json["max_delivery_schedule"],
            json["last_lightning_strike"] ? dayjs(json["last_lightning_strike"]).tz(timezone) : null,
            json["lightning_all_clear"],
            json["delivery_hours_all_day"],
            json["gc_can_approve_trade_deliveries"],
            json["require_vendor_info_deliveries"],
            json["use_lead_times"],
            json["action_items_email_day"],
            json["action_items_email_time"] ? dayjs(json["action_items_email_time"]).tz(timezone) : null,
            json["detail"],
            json["lead_times_default_lead_time"],
            json["lead_times_default_check_in_frequency"],
            json["lead_times_review_duration"],
            json["lead_times_order_delay"],
            json["lead_times_required_on_jobsite_require_gc"],
            json["command_center_settings"],
            json["archived"],
            json["integration_source_project_id"],
            json["integration_access_info"],
            json["integration_source"],
            json["integration_source_company_id"],
            json["procurement_sync_time"] ? dayjs(json["procurement_sync_time"]).tz(timezone) : null,
            json["delivery_approval_require_final"],
            json["integration_last_synced_submittals"] ? dayjs(json["integration_last_synced_submittals"]).tz(timezone) : null,
            json["integration_last_synced_activities"] ? dayjs(json["integration_last_synced_activities"]).tz(timezone) : null,
            json["integration_user_upload_activities_filename"],
            json["procurement_sync_do_submittals"],
            json["procurement_sync_do_activities"],
            json["notification_lists_teams"],
            json["notification_lists_areas"],
            json["notification_lists_equipments"],
            json["use_advanced_delivery_logic_pending"],
            json["overview_email_day"],
            json["overview_email_time"] ? dayjs(json["overview_email_time"]).tz(timezone) : null,
            json["use_deliveries"],
            json["lead_times_default_activity_dependency"],
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["layers"]?.map((item: any) => Layer.fromApiObject(item, timezone)),
            json["material_groups"]?.map((item: any) => MaterialGroup.fromApiObject(item, timezone)),
            json["materials"]?.map((item: any) => Material.fromApiObject(item, timezone)),
            json["orders"]?.map((item: any) => Order.fromApiObject(item, timezone)),
            json["permissions"]?.map((item: any) => Permission.fromApiObject(item, timezone)),
            json["equipment"]?.map((item: any) => Equipment.fromApiObject(item, timezone)),
            json["deliveries"]?.map((item: any) => Delivery.fromApiObject(item, timezone)),
            json["values"]?.map((item: any) => Value.fromApiObject(item, timezone)),
            json["submittals"]?.map((item: any) => Submittal.fromApiObject(item, timezone)),
            json["jobsite_exception_days"]?.map((item: any) => JobsiteExceptionDay.fromApiObject(item, timezone)),
            json["messages"]?.map((item: any) => Message.fromApiObject(item, timezone)),
            json["projects_weather_alerts"]?.map((item: any) => ProjectsWeatherAlert.fromApiObject(item, timezone)),
            json["order_exports"]?.map((item: any) => OrderExport.fromApiObject(item, timezone)),
            json["activities"]?.map((item: any) => Activity.fromApiObject(item, timezone)),
            json["notification_lists"]?.map((item: any) => NotificationList.fromApiObject(item, timezone)),
            json["vendor_links"]?.map((item: any) => VendorLink.fromApiObject(item, timezone)),
            json["owning_team"] == null ? json["owning_team"] : Team.fromApiObject(json["owning_team"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            project_name: this.projectName,
            address_1: this.address1,
            address_2: this.address2,
            city: this.city,
            state: this.state,
            zip_code: this.zipCode,
            lat: this.lat,
            lon: this.lon,
            listed: this.listed,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            contacts_list: this.contactsList,
            lat_override: this.latOverride,
            lon_override: this.lonOverride,
            use_override: this.useOverride,
            build_central_id: this.buildCentralId,
            project_has_been_modified: this.projectHasBeenModified,
            owning_team_id: this.owningTeamId,
            unique_token: this.uniqueToken,
            delivery_hours_start: this.deliveryHoursStart,
            delivery_hours_end: this.deliveryHoursEnd,
            use_advanced_delivery_logic: this.useAdvancedDeliveryLogic,
            use_advanced_delivery_logic_map_marker: this.useAdvancedDeliveryLogicMapMarker,
            use_advanced_delivery_logic_equipment: this.useAdvancedDeliveryLogicEquipment,
            use_advanced_delivery_logic_teams: this.useAdvancedDeliveryLogicTeams,
            auto_approve_guest_token: this.autoApproveGuestToken,
            delivery_created_approval_require_teams: this.deliveryCreatedApprovalRequireTeams,
            delivery_scheduled_approval_require_teams: this.deliveryScheduledApprovalRequireTeams,
            delivery_approval_require_gc: this.deliveryApprovalRequireGc,
            auto_approve_token: this.autoApproveToken,
            share_eta: this.shareEta,
            share_delivery_vehicle: this.shareDeliveryVehicle,
            delivery_logging: this.deliveryLogging,
            enable_vendor_scheduling: this.enableVendorScheduling,
            use_advanced_delivery_logic_trades: this.useAdvancedDeliveryLogicTrades,
            timezone: this.timezone,
            integration_daily_log_deliveries: this.integrationDailyLogDeliveries,
            integration_submittals_deliveries: this.integrationSubmittalsDeliveries,
            integration_import_members: this.integrationImportMembers,
            work_week_days: this.workWeekDays,
            observed_federal_holidays: this.observedFederalHolidays,
            use_advanced_delivery_logic_exception_days: this.useAdvancedDeliveryLogicExceptionDays,
            enable_lightning_alerts: this.enableLightningAlerts,
            lightning_alert_distance: this.lightningAlertDistance,
            lightning_all_clear_duration: this.lightningAllClearDuration,
            enable_weather_severe: this.enableWeatherSevere,
            enable_weather_threats: this.enableWeatherThreats,
            lightning_alerts_channel: this.lightningAlertsChannel,
            weather_threats_channel: this.weatherThreatsChannel,
            weather_severe_channel: this.weatherSevereChannel,
            min_delivery_schedule: this.minDeliverySchedule,
            max_delivery_schedule: this.maxDeliverySchedule,
            last_lightning_strike: this.lastLightningStrike,
            lightning_all_clear: this.lightningAllClear,
            delivery_hours_all_day: this.deliveryHoursAllDay,
            gc_can_approve_trade_deliveries: this.gcCanApproveTradeDeliveries,
            require_vendor_info_deliveries: this.requireVendorInfoDeliveries,
            use_lead_times: this.useLeadTimes,
            action_items_email_day: this.actionItemsEmailDay,
            action_items_email_time: this.actionItemsEmailTime,
            detail: this.detail,
            lead_times_default_lead_time: this.leadTimesDefaultLeadTime,
            lead_times_default_check_in_frequency: this.leadTimesDefaultCheckInFrequency,
            lead_times_review_duration: this.leadTimesReviewDuration,
            lead_times_order_delay: this.leadTimesOrderDelay,
            lead_times_required_on_jobsite_require_gc: this.leadTimesRequiredOnJobsiteRequireGc,
            command_center_settings: this.commandCenterSettings,
            archived: this.archived,
            integration_source_project_id: this.integrationSourceProjectId,
            integration_access_info: this.integrationAccessInfo,
            integration_source: this.integrationSource,
            integration_source_company_id: this.integrationSourceCompanyId,
            procurement_sync_time: this.procurementSyncTime,
            delivery_approval_require_final: this.deliveryApprovalRequireFinal,
            integration_last_synced_submittals: this.integrationLastSyncedSubmittals,
            integration_last_synced_activities: this.integrationLastSyncedActivities,
            integration_user_upload_activities_filename: this.integrationUserUploadActivitiesFilename,
            procurement_sync_do_submittals: this.procurementSyncDoSubmittals,
            procurement_sync_do_activities: this.procurementSyncDoActivities,
            notification_lists_teams: this.notificationListsTeams,
            notification_lists_areas: this.notificationListsAreas,
            notification_lists_equipments: this.notificationListsEquipments,
            use_advanced_delivery_logic_pending: this.useAdvancedDeliveryLogicPending,
            overview_email_day: this.overviewEmailDay,
            overview_email_time: this.overviewEmailTime,
            use_deliveries: this.useDeliveries,
            lead_times_default_activity_dependency: this.leadTimesDefaultActivityDependency,
            audits: this.audits?.map(item => item.toApiObject()),
            layers: this.layers?.map(item => item.toApiObject()),
            material_groups_attributes: this.materialGroups?.map(item => item.toApiObject()),
            materials_attributes: this.materials?.map(item => item.toApiObject()),
            orders_attributes: this.orders?.map(item => item.toApiObject()),
            permissions: this.permissions?.map(item => item.toApiObject()),
            equipment_attributes: this.equipment?.map(item => item.toApiObject()),
            deliveries_attributes: this.deliveries?.map(item => item.toApiObject()),
            values_attributes: this.values?.map(item => item.toApiObject()),
            submittals: this.submittals?.map(item => item.toApiObject()),
            jobsite_exception_days: this.jobsiteExceptionDays?.map(item => item.toApiObject()),
            messages: this.messages?.map(item => item.toApiObject()),
            projects_weather_alerts: this.projectsWeatherAlerts?.map(item => item.toApiObject()),
            order_exports: this.orderExports?.map(item => item.toApiObject()),
            activities: this.activities?.map(item => item.toApiObject()),
            notification_lists: this.notificationLists?.map(item => item.toApiObject()),
            vendor_links: this.vendorLinks?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Project {
        const clone = Object.create(this) as Project;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
