import { notification } from "antd";
import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { globalAppConfig } from "../../config";
import TeamInfo from "../../teamInfo";

export function getTeam(team_subdomain): Promise<object> {
    const url = Helper.baseUrlAltSubdomain(team_subdomain) + "/teams/1";

    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.length === 0) {
                    return null;
                }
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getTeamById(id: number): Promise<object> {
    const url = `${Helper.baseUrlWithPort()}/teams/${id}`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.length === 0) {
                    return null;
                }
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 * Get the list of projects that the team is involved with (owned and joined)
 * @returns Object of arrays of projects and joined projects for the current team, or null if no signed in
 *  response {
 *      projects: [
 *          { id: 1, project_name: "example1", ... },
 *          { id: 2, project_name: "example2", ... }
 *      ],
 *      joined_project_links: [
 *          { id: 3, project_name: "example3", ...},
 *          { id: 4, project_name: "example4", ...}
 *      ]
 *  }
 */
export function getProjectsOnTeam(
    subdomain: string
): Promise<{
    member_of: any[];
    not_member_of: any[];
    projects: any[];
    recent_projects: any[];
    vendor_for: any[];
} | null> {
    const url = Helper.baseUrlAltSubdomain(subdomain) + "/projects";

    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 * Get the current subdomains team
 * @returns Team Info or null if failed to get
 */
export function getCurrentSubdomainTeam(): Promise<object> {
    const url = "/teams/current";
    return trackPromise(
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to get team info");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 * Gets the current users access level to the current team based on subdomain
 * @returns Access level info or null
 */
export function getCurrentSubdomainTeamAccessLevel(): Promise<{
    level: number;
    message: string;
}> {
    const url = "/teams/current/access_level";
    return trackPromise(
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to get team info");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 * Create a new team using the given info
 * @param teamInfo Info to fill in the team for team params
 * @returns Newly created team info, or null if error
 */
export function postTeam(teamInfo, addCurrentUserToTeam: boolean = false): Promise<any | null> {
    const url = "/teams";
    return fetch(url, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ team: teamInfo, add_current_user_to_team: addCurrentUserToTeam ? "true" : null }),
        credentials: "include",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (response.status == 422) {
                return response.json();
            }

            throw new Error("Failed to create team");
        })
        .then((response) => {
            if (response.error) {
                return null;
            }
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function putTeamUpdateMembers(teamInfo: TeamInfo): Promise<object> {
    const url = "/teams/update_members";
    return fetch(url, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ users: teamInfo.users }),
        credentials: "include",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (response.status == 422) {
                return response.json();
            }

            throw new Error("Failed to update team");
        })
        .then((response) => {
            if (response.error) {
                return null;
            }
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function putTeam(team_id: number, teamInfo: any): Promise<object> {
    const url = "/teams/" + team_id;
    return fetch(url, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ team: teamInfo }),
        credentials: "include",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (response.status == 422) {
                return response.json();
            }

            throw new Error("Failed to update team");
        })
        .then((response) => {
            if (response.error) {
                return null;
            }
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function requestToJoinTeam(team_id: number): Promise<any> {
    const url = "/teams/request_to_join_team/" + team_id;
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                let message, description;
                switch (response.message) {
                    case "RequestAutoApproved":
                        message = "The team has auto approved your join request";
                        description = "You match a rule for auto approval. Automatically adding you to the team";
                        break;
                    case "RequestAlreadySent":
                        message = "Request already sent to team";
                        description =
                            "You have already requested to join this team. Sending a reminder to the team admin";
                        break;
                    default: //Default is "RequestSent"
                        message = "Your request to join has been sent";
                        description = "The team has received your request";
                }
                notification.success({
                    message: message,
                    description: description,
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });
                return response;
            })
            .catch(() => {
                notification.error({
                    message: "Your request to join failed to be sent",
                    description: "Failure to send request",
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });
            })
    );
}

export function getUniqueSubdomainForTeam(suggestion: string): Promise<string> {
    const url = Helper.baseUrlAltSubdomain("teams") + `/subdomains/suggest?company_name=${suggestion}`;
    return fetch(url, {
        credentials: "include",
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Failed to get unique team subdomain");
        })
        .then((response) => {
            return response.suggestion;
        })
        .catch(() => {
            return null;
        });
}

export function shareTeam(subdomain: string, contacts: { contact_info: string }[]): Promise<any> {
    const url = Helper.baseUrlAltSubdomain(subdomain) + "/teams/share";

    return trackPromise(
        fetch(url, {
            credentials: "include",
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ contacts: contacts }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to share company");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function inviteToTeam(
    contacts: { contact_info: string }[],
    subdomain: string,
    invited_project_token?: string
): Promise<any> {
    let url = "/teams/invite";
    contacts = contacts.map((contact) => {
        contact.contact_info = contact.contact_info.trim();
        return contact;
    });

    if (subdomain && subdomain.length > 0) {
        url = Helper.baseUrlAltSubdomain(subdomain) + "/teams/invite";
    }

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                contacts: contacts,
                project_unique_token: invited_project_token,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function manageActiveInactiveProjects(active_projects: any[], inactive_projects: any[]): Promise<boolean> {
    const url = "/teams/manage_active_inactive";

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                active_projects: active_projects,
                inactive_projects: inactive_projects,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            })
    );
}

/**
 * Get the current team's profile pic
 * @returns team profile pic url, or null if not signed in or empty
 */
export function getTeamPic(team_subdomain: string): Promise<string | null> {
    const url = `${Helper.baseUrlAltSubdomain(team_subdomain)}/teams/picture`;
    return fetch(url, {
        method: "GET",
        credentials: "include",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((response) => {
            if (response && response.picture) {
                return response.picture;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        });
}

export function getTeamPicBase64(team_subdomain: string): Promise<string | null> {
    const url = `${Helper.baseUrlAltSubdomain(team_subdomain)}/teams/picture?download=true`;
    return fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = function () {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(blob);
                })
        );
}

export function uploadTeamPic(team: TeamInfo, imageAsDataURL: string): Promise<boolean> {
    const url = `${Helper.baseUrlAltSubdomain(team.subdomain)}/teams/picture`;
    return Helper.plotFetch(url, {
        method: "POST",
        body: JSON.stringify({ picture: imageAsDataURL }),
    })
        .then((response) => {
            if (response.ok) {
                return true;
            }
            return false;
        })
        .catch(() => {
            return false;
        });
}

/**
 * Deletes the team's current profile picture
 * @returns boolean indicating success in removal
 */

export function deleteTeamPic(team: TeamInfo): Promise<boolean> {
    const url = `${Helper.baseUrlAltSubdomain(team.subdomain)}/teams/${team.id}/picture`;
    return Helper.plotFetch(url, {
        method: "DELETE",
        credentials: "include",
    })
        .then((response) => {
            if (response.ok) {
                return true;
            }
            return false;
        })
        .catch(() => {
            return false;
        });
}

export function ssoConfig(team_subdomain: string): Promise<any | null> {
    const url = `${Helper.baseUrlAltSubdomain(team_subdomain)}/teams/sso_config`;

    return Helper.plotFetch(url, {
        method: "GET",
        credentials: "include",
    })
        .then((response) => response.json())
        .then((response) => {
            return response;
        })
        .catch(() => {
            return null;
        });
}
