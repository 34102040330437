import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import Permission from "../../Permission";
import UserInfo from "../../userInfo";

/** Get the current user's info
 * @returns user info, or null if not signed in
 */
export function getCurrentUserInfo(): Promise<any | null> {
    const url = "/users/me";
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return null;
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 *  Update the current user's info
 * @param userInfo Updated user info
 * @returns updated info, or null if not signed in
 */
export function putCurrentUserInfo(userInfo): Promise<{ message: string; data: object } | null> {
    if (userInfo) {
        const url = "/users/me";
        return trackPromise(
            fetch(url, {
                method: "put",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user: userInfo }),
                credentials: "include",
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then((err) => {
                            let message = err.message || "Network response was not ok.";
                            if (err["mobiles.mobile"]) {
                                message =
                                    "That mobile number is already associated with another User, please try another";
                            } else if (err["emails.email"]) {
                                message = "That email is already associated with another User, please try another";
                            }
                            throw new Error(message);
                        });
                    }
                })
                .then((data) => {
                    return { message: "Success", data: data };
                })
                .catch((err) => {
                    return { message: err.message, data: {} };
                })
        );
    }

    return Promise.resolve(null);
}

/**
 * Updates the current user's profile pic
 * @param imageDataURL Image data as url, see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * @returns boolean indicating success in update
 */
export function postCurrentUserPic(imageDataURL): Promise<boolean> {
    const url = Helper.baseUrlAltSubdomain("projects") + "/users/me/picture";
    return Helper.plotFetch(
        url,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ picture: imageDataURL }),
            credentials: "include",
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

/**
 * Deletes the user's current profile picture
 * @returns boolean indicating success in removal
 */

export function deleteCurrentUserPic(): Promise<boolean> {
    return trackPromise(
        fetch("/users/me/picture", {
            method: "DELETE",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return true;
                }
                return false;
            })
            .catch(() => {
                return false;
            })
    );
}

/**
 * Get the current user's profile pic
 * @returns user profile pic, or null if not signed in or empty
 */
export function getCurrentUserPic(): Promise<string | null> {
    const url = "/users/me/picture?t=";
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((response) => {
                if (response.picture) {
                    return response.picture;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 * Get the list of teams that the current user is on
 * @returns list of current users teams, or null if not signed in
 */
export function getCurrentUsersTeams(): Promise<any[] | null> {
    const url = "/users/me/teams";
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.length === 0) {
                    return null;
                }
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}
/**
 * Get a user's profile pics
 * @returns profile pic url, or null if not signed in or empty
 */
export function getUserPic(user_id: number): Promise<string | null> {
    const url = `/users/${user_id}/picture`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((response) => {
                if (response.picture) {
                    return response.picture;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            })
    );
}
export function getUserNotifications(): Promise<string | null> {
    const url = "/notifications/";
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getHighestProjectPermission(user: UserInfo, team_id: number, project_id: number) {
    let permission;

    if (user.permission(Permission.is_final_approver, team_id, project_id)) {
        permission = Permission.is_final_approver.perm;
    } else if (user.permission(Permission.is_lead, team_id, project_id)) {
        permission = Permission.is_lead.perm;
    } else if (user.permission(Permission.is_owner, team_id, project_id)) {
        permission = Permission.is_owner.perm;
    } else if (user.permission(Permission.is_member, team_id, project_id)) {
        permission = Permission.is_member.perm;
    } else if (user.permission(Permission.is_subscribed, team_id, project_id)) {
        permission = Permission.is_subscribed.perm;
    } else if (user.permission(Permission.is_invited, team_id, project_id)) {
        permission = Permission.is_invited.perm;
    } else {
        permission = " ";
    }

    return permission;
}

export function setCurrentTeam(team_id: number): Promise<boolean> {
    const url = `/users/me/set_current_team?team_id=${team_id}`;

    return trackPromise(
        fetch(url, {
            method: "POST",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return true;
                }
                return false;
            })
            .catch(() => {
                return false;
            })
    );
}
