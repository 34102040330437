/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Message.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Message } from "./Message";

import { Notification } from "./Notification";
import { Project } from "./Project";
import { Team } from "./Team";
import { User } from "./User";

export type messageType = "alert" | "delivery" | "logistic" | "request_to_join_project" | "request_to_join_team";

export const messageTypeValues = {"alert":0,"delivery":1,"logistic":2,"request_to_join_project":3,"request_to_join_team":4};

export abstract class MessageModel {
    id: number;
    projectId: number | null;
    teamId: number | null;
    senderId: number | null;
    body: string;
    messageType: messageType;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    archived: boolean;
    project: Project | null | undefined;
    team: Team | null | undefined;
    sender: User | null | undefined;
    notifications: Notification[] | undefined;

     
    constructor(
        id: number,
        projectId: number | null,
        teamId: number | null,
        senderId: number | null,
        body: string,
        messageType: messageType,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        archived: boolean,
        project: Project | null | undefined,
        team: Team | null | undefined,
        sender: User | null | undefined,
        notifications: Notification[] | undefined
    ) {
        this.id = id;
        this.projectId = projectId;
        this.teamId = teamId;
        this.senderId = senderId;
        this.body = body;
        this.messageType = messageType;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.archived = archived;
        this.project = project;
        this.team = team;
        this.sender = sender;
        this.notifications = notifications;
    }
     

    static fromApiObject(json: any, timezone: string): Message {
        const newObject = new Message(
            json["id"],
            json["project_id"],
            json["team_id"],
            json["sender_id"],
            json["body"],
            json["message_type"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["archived"],
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["team"] == null ? json["team"] : Team.fromApiObject(json["team"], timezone),
            json["sender"] == null ? json["sender"] : User.fromApiObject(json["sender"], timezone),
            json["notifications"]?.map((item: any) => Notification.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            project_id: this.projectId,
            team_id: this.teamId,
            sender_id: this.senderId,
            body: this.body,
            message_type: this.messageType,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            archived: this.archived,
            notifications: this.notifications?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Message {
        const clone = Object.create(this) as Message;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
