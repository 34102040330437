/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Permission.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Permission } from "./Permission";

import { Audit } from "./Audit";
import { Project } from "./Project";
import { Team } from "./Team";
import { User } from "./User";

export type permission = "is_invited" | "is_subscribed" | "is_member" | "is_lead" | "is_owner" | "is_plot_admin" | "is_admin" | "is_vendor" | "is_final_approver";

export const permissionValues = {"is_invited":0,"is_subscribed":1,"is_member":2,"is_lead":3,"is_owner":4,"is_plot_admin":5,"is_admin":6,"is_vendor":8,"is_final_approver":9};

export abstract class PermissionModel {
    id: number;
    userId: number | null;
    teamId: number | null;
    permission: permission;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    projectId: number | null;
    audits: Audit[] | undefined;
    user: User | null | undefined;
    team: Team | null | undefined;
    project: Project | null | undefined;

     
    constructor(
        id: number,
        userId: number | null,
        teamId: number | null,
        permission: permission,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        projectId: number | null,
        audits: Audit[] | undefined,
        user: User | null | undefined,
        team: Team | null | undefined,
        project: Project | null | undefined
    ) {
        this.id = id;
        this.userId = userId;
        this.teamId = teamId;
        this.permission = permission;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.projectId = projectId;
        this.audits = audits;
        this.user = user;
        this.team = team;
        this.project = project;
    }
     

    static fromApiObject(json: any, timezone: string): Permission {
        const newObject = new Permission(
            json["id"],
            json["user_id"],
            json["team_id"],
            json["permission"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["project_id"],
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone),
            json["team"] == null ? json["team"] : Team.fromApiObject(json["team"], timezone),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            user_id: this.userId,
            team_id: this.teamId,
            permission: this.permission,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            project_id: this.projectId,
            audits: this.audits?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Permission {
        const clone = Object.create(this) as Permission;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
