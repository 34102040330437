import * as Sentry from "@sentry/react";
import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";

/**
 * Get notifications from PLOT API. Can filter on page number and feed. Returns 25 results.
 * @param token is the project token you are requesting.
 * @param page returns the page you are requesting. I.E. Page 2 returns next 25 results after page 1
 * @param message_type returns messages that match that filter type. Leave blank to return all messages
 */
export function getProjectNotifications(token: string, page_number: number, message_type: number): Promise<any> {
    const url = new URL(`${Helper.baseUrlWithPort()}/projects/${token}/notifications`);
    if (message_type !== null && message_type > -1) {
        url.searchParams.append("message_type", message_type.toString());
    }
    if (page_number) {
        url.searchParams.append("page", page_number.toString());
    }

    return trackPromise(
        fetch(url.toString(), {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .catch(() => {})
    );
}

export function archiveProjectNotification(token: string, notification_id: number): Promise<any> {
    return trackPromise(
        fetch(`${Helper.baseUrlWithPort()}/projects/${token}/notifications/${notification_id}`, {
            method: "DELETE",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Sentry.captureException(new Error("Unable to archive project notification"));
                }
            })
            .catch(() => {
                return Sentry.captureException(new Error("Unable to archive project notification"));
            })
    );
}
