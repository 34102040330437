import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { globalAppConfig } from "../../config";
import PlotAPI from "../../plotAPI";

export function getProjectMapLayers(projectToken: string, subdomain: string): Promise<any[]> {
    const url = `${Helper.baseUrlAltSubdomain(subdomain)}/projects/${projectToken}/layers`;
    return fetch(url)
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error("Failed to get project layers");
        })
        .then((res) => {
            res.forEach((layer) => {
                {
                    layer.features.sort((a, b) => {
                        return a.properties.plot.areas_index - b.properties.plot.areas_index;
                    });
                }
            });
            return res;
        })
        .catch((err) => {
            Sentry.captureException(err);
        });
}
export function getActiveDeliveries(
    projectToken: string,
    subdomain: string = ""
): Promise<{ id: number; features: { id: number; uuid: string; active_deliveries: any[] }[] }[]> {
    let url = "/projects/" + projectToken + "/deliveries/locations";
    if (subdomain && subdomain.length > 0) {
        url = Helper.baseUrlAltSubdomain(subdomain) + url;
    }
    return fetch(url)
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error("Failed to get project deliveries");
        })
        .catch((err) => {
            Sentry.captureException(err);
        });
}

export async function getSingleMapObjectInformation(project_token: string, map_object_id: string): Promise<object[]> {
    const url = "/projects/" + project_token + "/layers/";
    return fetch(url + "?f=geojson")
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error("Failed to get project layers");
        })
        .then((res) => {
            const layerIndex = res.findIndex((layer) => layer.features.find((area) => area.id === map_object_id));
            const area = res[layerIndex].features.find((area) => area.id === map_object_id);
            return area;
        })
        .catch((error) => {
            Sentry.captureException(error);
        });
}

export function getDeliveriesForLocation(activeDeliveries, object_id: string, index: number) {
    const deliveryArea = activeDeliveries[index].features.find((area) => {
        return area.uuid === object_id;
    });

    return deliveryArea;
}

export function getDirections(
    startingLon: number,
    startingLat: number,
    endingLon: number,
    endingLat: number
): Promise<object[]> {
    const url =
        "https://api.mapbox.com/directions/v5/mapbox/driving-traffic/" +
        startingLon +
        "%2C" +
        startingLat +
        "%3B" +
        endingLon +
        "%2C" +
        endingLat +
        "?alternatives=true&geometries=geojson&overview=simplified&steps=false&access_token=" +
        globalAppConfig.MAP_BOX_ACCESS_TOKEN;
    return fetch(url).then((res) => {
        if (res.ok) {
            return res.json();
        }
        throw new Error("Failed to get directions");
    });
}

export function getMapObjectImage(mapObject): Promise<string> {
    //As of right now only points can be used to generate images
    function returnCoordinates(object) {
        let coordinates = [];
        if (object.geometry.type === "Point") {
            coordinates = object.geometry.coordinates;
        } else if (object.geometry.type === "LineString") {
            coordinates = object.geometry.coordinates[0];
        } else {
            coordinates = object.geometry.coordinates[0][0];
        }
        return coordinates;
    }
    const mapMarkerIcon = "https://i.ibb.co/fdcLfgH/8324266-ui-essential-app-location-map-icon-2.png";
    const zoom = 15;
    const pitch = 0;
    const style = "satellite-streets-v11";
    const mapObjectCoordinates = returnCoordinates(mapObject);
    const imageSize = "300x300";
    const url =
        "https://api.mapbox.com/styles/v1/mapbox/" +
        style +
        "/static/url-" +
        encodeURIComponent(mapMarkerIcon) +
        "(" +
        mapObjectCoordinates[0] +
        "," +
        mapObjectCoordinates[1] +
        ")/" +
        mapObjectCoordinates[0] +
        "," +
        mapObjectCoordinates[1] +
        "," +
        zoom +
        "," +
        pitch +
        "/" +
        imageSize +
        "?access_token=" +
        globalAppConfig.MAP_BOX_ACCESS_TOKEN;
    return fetch(url)
        .then((res) => {
            if (res.ok) {
                return res.blob();
            }

            throw new Error("Failed to get location image");
        })
        .then((res) => {
            return URL.createObjectURL(res);
        })
        .catch(() => {
            return "";
        });
}

export function findLayerIndex(array: any[], val: string) {
    return array.findIndex((layer) => layer.features.find((area) => area.id === val));
}
export function findAreaIndex(area, val: string) {
    return area[this.findLayerIndex(area, val)].features.findIndex((area) => area.id === val);
}
export function findObjectById(layers, id: string) {
    const result = layers[this.findLayerIndex(layers, id)].features[this.findAreaIndex(layers, id)];
    return result;
}

export function saveMapLayers(
    map_layers: any[],
    currentProjectInfo: any,
    tagged_teams: number[] = [],
    area_updated?: any
): Promise<any> {
    let error;
    return Promise.all(
        map_layers.map((layer) => {
            const url = `/projects/${currentProjectInfo.unique_token}/layers/${layer.properties.id}`;
            return trackPromise(
                fetch(url, {
                    method: "put",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        layer: layer,
                        area_updated:
                            layer.properties.id === area_updated?.properties?.plot?.layer_id &&
                            area_updated.properties.plot.id,
                        tagged_teams: tagged_teams,
                    }),
                })
                    .then((res) => {
                        return Promise.resolve(res.json());
                    })
                    .catch((err) => {
                        return Promise.resolve(err.json());
                    })
            );
        })
    )
        .then((response) => {
            if (response.some((res) => res.error)) {
                error = response.find((res) => res.error);
                return Promise.reject(error);
            }
            const jobsiteEntrance = map_layers
                .flatMap((layer) => layer.features)
                .find((area) => {
                    return area.properties.plot.area_name === "Jobsite Entrance";
                });
            if (
                jobsiteEntrance.geometry.coordinates[0] !== currentProjectInfo.lon ||
                jobsiteEntrance.geometry.coordinates[1] !== currentProjectInfo.lat
            ) {
                const projectInfo = currentProjectInfo;
                projectInfo.lon = jobsiteEntrance.geometry.coordinates[0];
                projectInfo.lat = jobsiteEntrance.geometry.coordinates[1];
                return PlotAPI.putProjectInfo(currentProjectInfo.unique_token, projectInfo);
            }
            return response;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export function destroyMapLayer(projectToken: string, layerId: number): Promise<any> {
    const url = `/projects/${projectToken}/layers/${layerId}`;
    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error("Failed to get destroy map layer");
        })
    );
}

export function archiveMapObject(currentProjectInfo: any, archive_area?: any): Promise<any> {
    const url = `/projects/${currentProjectInfo.unique_token}/layers/${archive_area.properties.plot.layer_id}/areas/${archive_area.properties.plot.id}`;
    return Promise.resolve(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error("Failed to get archive map object");
        })
    );
}

export function destroyMapArea(projectToken: string, layerId: number, areaId: number): Promise<any> {
    const url = `/projects/${projectToken}/layers/${layerId}/areas/${areaId}`;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => {
                if (res.ok) {
                    notification.success({
                        message: "Layer Deleted",
                        description: "The layer has been deleted",
                    });
                    return res.json();
                }

                throw new Error("Failed to get destroy map area");
            })
            .catch(() => {
                return null;
            })
    );
}

export function getUpcomingDeliveriesForLocation(
    project_token: string,
    currentObject: any,
    query: URLSearchParams
): Promise<any[]> {
    const url = new URL(`${Helper.baseUrlWithPort()}/projects/${project_token}/deliveries/query`);
    for (const [key, value] of query.entries()) {
        url.searchParams.append(key, value);
    }
    if (currentObject.properties.plot.equipment_id) {
        url.searchParams.append("equipment_ids", currentObject.properties.plot.equipment_id);
    }
    if (currentObject.properties.plot.is_drop_off) {
        url.searchParams.append("drop_off", currentObject.id);
    }
    if (currentObject.properties.plot.is_waypoint) {
        url.searchParams.append("waypoint", currentObject.id);
    }

    return trackPromise(
        fetch(url.toString(), {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("Failed to put");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return [];
            })
    );
}

export function geocoder(placeType, value) {
    const mapboxAPIurl = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
    const queryURL = mapboxAPIurl + value + ".json?" + "&access_token=" + globalAppConfig.MAP_BOX_ACCESS_TOKEN;
    trackPromise(
        fetch(queryURL)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((response) => {
                return response;
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
    );
}
