/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Equipment.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Equipment } from "./Equipment";

import { Area } from "./Area";
import { AreaHasEquipment } from "./AreaHasEquipment";
import { DeliveriesEquipment } from "./DeliveriesEquipment";
import { EquipmentBreakTime } from "./EquipmentBreakTime";
import { EquipmentTempClosure } from "./EquipmentTempClosure";
import { Project } from "./Project";

export abstract class EquipmentModel {
    id: number;
    equipmentType: string | null;
    equipmentName: string | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    deliveryHoursStart: Dayjs | null;
    deliveryHoursEnd: Dayjs | null;
    useCustomHours: boolean | null;
    openDate: Dayjs | null;
    closeDate: Dayjs | null;
    projectId: number | null;
    project: Project | null | undefined;
    deliveriesEquipment: DeliveriesEquipment[] | undefined;
    equipmentBreakTimes: EquipmentBreakTime[] | undefined;
    equipmentTempClosures: EquipmentTempClosure[] | undefined;
    areaHasEquipment: AreaHasEquipment[] | undefined;
    area: Area | null | undefined;

     
    constructor(
        id: number,
        equipmentType: string | null,
        equipmentName: string | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        deliveryHoursStart: Dayjs | null,
        deliveryHoursEnd: Dayjs | null,
        useCustomHours: boolean | null,
        openDate: Dayjs | null,
        closeDate: Dayjs | null,
        projectId: number | null,
        project: Project | null | undefined,
        deliveriesEquipment: DeliveriesEquipment[] | undefined,
        equipmentBreakTimes: EquipmentBreakTime[] | undefined,
        equipmentTempClosures: EquipmentTempClosure[] | undefined,
        areaHasEquipment: AreaHasEquipment[] | undefined,
        area: Area | null | undefined
    ) {
        this.id = id;
        this.equipmentType = equipmentType;
        this.equipmentName = equipmentName;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.deliveryHoursStart = deliveryHoursStart;
        this.deliveryHoursEnd = deliveryHoursEnd;
        this.useCustomHours = useCustomHours;
        this.openDate = openDate;
        this.closeDate = closeDate;
        this.projectId = projectId;
        this.project = project;
        this.deliveriesEquipment = deliveriesEquipment;
        this.equipmentBreakTimes = equipmentBreakTimes;
        this.equipmentTempClosures = equipmentTempClosures;
        this.areaHasEquipment = areaHasEquipment;
        this.area = area;
    }
     

    static fromApiObject(json: any, timezone: string): Equipment {
        const newObject = new Equipment(
            json["id"],
            json["equipment_type"],
            json["equipment_name"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["delivery_hours_start"] ? dayjs(json["delivery_hours_start"]).tz(timezone) : null,
            json["delivery_hours_end"] ? dayjs(json["delivery_hours_end"]).tz(timezone) : null,
            json["use_custom_hours"],
            json["open_date"] ? dayjs(json["open_date"]).tz(timezone) : null,
            json["close_date"] ? dayjs(json["close_date"]).tz(timezone) : null,
            json["project_id"],
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["deliveries_equipment"]?.map((item: any) => DeliveriesEquipment.fromApiObject(item, timezone)),
            json["equipment_break_times"]?.map((item: any) => EquipmentBreakTime.fromApiObject(item, timezone)),
            json["equipment_temp_closures"]?.map((item: any) => EquipmentTempClosure.fromApiObject(item, timezone)),
            json["area_has_equipment"]?.map((item: any) => AreaHasEquipment.fromApiObject(item, timezone)),
            json["area"] == null ? json["area"] : Area.fromApiObject(json["area"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            equipment_type: this.equipmentType,
            equipment_name: this.equipmentName,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            delivery_hours_start: this.deliveryHoursStart,
            delivery_hours_end: this.deliveryHoursEnd,
            use_custom_hours: this.useCustomHours,
            open_date: this.openDate,
            close_date: this.closeDate,
            project_id: this.projectId,
            deliveries_equipment: this.deliveriesEquipment?.map(item => item.toApiObject()),
            equipment_break_times: this.equipmentBreakTimes?.map(item => item.toApiObject()),
            equipment_temp_closures: this.equipmentTempClosures?.map(item => item.toApiObject()),
            area_has_equipment: this.areaHasEquipment?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Equipment {
        const clone = Object.create(this) as Equipment;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
