import { trackPromise } from "react-promise-tracker";

export function getFederalHolidays(project_token: string, year: number): Promise<any[]> {
    const url = `/projects/${project_token}/federal_holidays?year=${year}`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return [];
            })
    );
}

export function setObservedFederalHolidays(project_token: string, observed_federal_holidays: number[]): Promise<null> {
    const url = "/projects/" + project_token + "/federal_holidays";

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                observed_federal_holidays: observed_federal_holidays,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getJobsiteExceptionDays(
    project_token: string,
    year: number
): Promise<{
    observed_federal_holidays: {
        holiday_date: string;
        holiday_year: number;
        federal_holiday: { holiday_name: string };
    }[];
    exception_days: {
        id: number;
        exception_name: string;
        exception_date: string;
        exception_date_end: string;
        project_token: string;
    }[];
}> {
    const url = "/projects/" + project_token + "/jobsite_exception_days?year=" + year;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to get holidays");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return { observed_federal_holidays: [], exception_days: [] };
            })
    );
}

export function createExceptionDayForJobsite(
    project_token: string,
    exception_name: string,
    exception_date: string,
    exception_date_end: string
): Promise<boolean> {
    const url = "/projects/" + project_token + "/jobsite_exception_days";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                jobsite_exception_day: {
                    exception_name: exception_name,
                    exception_date: exception_date,
                    exception_date_end: exception_date_end,
                },
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function deleteExceptionDayForJobsite(project_token: string, exception_id: number) {
    const url = "/projects/" + project_token + "/jobsite_exception_days/" + exception_id;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}
