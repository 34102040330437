import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";

export function claimTV(connect_code: string): Promise<boolean> {
    const url = `${Helper.baseUrlAltSubdomain("api")}/fire_tv/claim/${connect_code}`;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }

                return null;
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}
