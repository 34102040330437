import { DeliveryModel } from "./Delivery.model";

export class Delivery extends DeliveryModel {
    receivingTeamId(): number | null {
        if (!this.deliveryHasTeams) {
            return null;
        }

        if (this.deliveryHasTeams.length == 1) {
            return this.deliveryHasTeams[0].teamId;
        }

        return this.deliveryHasTeams?.find((team) => team.deliveryRole == "receiving_team")?.teamId ?? null;
    }

    /**
     * Does this delivery have the GC team tagged for approval? This would mean another team is the receiving team and they are just attached for approval
     * @returns
     */
    hasGCTeamTaggedForApproval(): boolean {
        if (!this.deliveryHasTeams) {
            return false;
        }

        return (
            this.deliveryHasTeams?.length == 2 && this.deliveryHasTeams.some((team) => team.deliveryRole == "gc_team")
        );
    }
}
