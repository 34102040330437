/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Comment.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Comment } from "./Comment";

import { User } from "./User";

export abstract class CommentModel {
    id: number;
    body: string | null;
    commentableType: string;
    commentableId: number;
    userId: number;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    user: User | null | undefined;

     
    constructor(
        id: number,
        body: string | null,
        commentableType: string,
        commentableId: number,
        userId: number,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        user: User | null | undefined
    ) {
        this.id = id;
        this.body = body;
        this.commentableType = commentableType;
        this.commentableId = commentableId;
        this.userId = userId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.user = user;
    }
     

    static fromApiObject(json: any, timezone: string): Comment {
        const newObject = new Comment(
            json["id"],
            json["body"],
            json["commentable_type"],
            json["commentable_id"],
            json["user_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            body: this.body,
            commentable_type: this.commentableType,
            commentable_id: this.commentableId,
            user_id: this.userId,
            created_at: this.createdAt,
            updated_at: this.updatedAt
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Comment {
        const clone = Object.create(this) as Comment;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
