class Permission {
    perm: string = "";

    static is_invited: Permission = new Permission("is_invited");
    static is_subscribed: Permission = new Permission("is_subscribed");
    static is_member: Permission = new Permission("is_member");
    static is_lead: Permission = new Permission("is_lead");
    static is_owner: Permission = new Permission("is_owner");
    static is_plot_admin: Permission = new Permission("is_plot_admin");
    static is_admin: Permission = new Permission("is_admin");
    static is_final_approver: Permission = new Permission("is_final_approver");

    constructor(perm: string) {
        this.perm = perm;
    }
}

export default Permission;
