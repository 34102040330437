import { notification } from "antd";
import Helper from "../../Helper";
import { plotFetch } from "../Helper/ApiRequest";
import { ChangeHistoryPoint } from "../Materials/materialTypes";

export function getMaterialAudits(team_subdomain: string, project_token: string): Promise<ChangeHistoryPoint[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_audits";

    return plotFetch(url, { method: "get" }, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            const history = new Array<ChangeHistoryPoint>();

            for (const point of response) {
                history.push(ChangeHistoryPoint.fromApiObject(point));
            }

            return history;
        })
        .catch(() => {
            notification.error({
                message: "Failed to get material audits",
                description: "Please try again later",
            });

            return [];
        });
}
