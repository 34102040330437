/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Material.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Material } from "./Material";

import { Audit } from "./Audit";
import { MaterialGroup } from "./MaterialGroup";
import { MaterialsSubmittal } from "./MaterialsSubmittal";
import { Order } from "./Order";
import { Project } from "./Project";

export abstract class MaterialModel {
    id: number;
    materialGroupId: number | null;
    archived: boolean | null;
    materialName: string | null;
    unitOfMeasure: string | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    checkInFrequency: number | null;
    projectId: number | null;
    leadTime: number | null;
    approvalDate: Dayjs | null;
    requiredOnJobsite: Dayjs | null;
    submitDate: Dayjs | null;
    reviewDuration: number | null;
    orderDelay: number | null;
    submittalNumber: string | null;
    specNumber: string | null;
    nextOrderBy: Dayjs | null;
    nextRequiredOnJobsite: Dayjs | null;
    audits: Audit[] | undefined;
    project: Project | null | undefined;
    materialGroup: MaterialGroup | null | undefined;
    orders: Order[] | undefined;
    materialsSubmittals: MaterialsSubmittal[] | undefined;

     
    constructor(
        id: number,
        materialGroupId: number | null,
        archived: boolean | null,
        materialName: string | null,
        unitOfMeasure: string | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        checkInFrequency: number | null,
        projectId: number | null,
        leadTime: number | null,
        approvalDate: Dayjs | null,
        requiredOnJobsite: Dayjs | null,
        submitDate: Dayjs | null,
        reviewDuration: number | null,
        orderDelay: number | null,
        submittalNumber: string | null,
        specNumber: string | null,
        nextOrderBy: Dayjs | null,
        nextRequiredOnJobsite: Dayjs | null,
        audits: Audit[] | undefined,
        project: Project | null | undefined,
        materialGroup: MaterialGroup | null | undefined,
        orders: Order[] | undefined,
        materialsSubmittals: MaterialsSubmittal[] | undefined
    ) {
        this.id = id;
        this.materialGroupId = materialGroupId;
        this.archived = archived;
        this.materialName = materialName;
        this.unitOfMeasure = unitOfMeasure;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.checkInFrequency = checkInFrequency;
        this.projectId = projectId;
        this.leadTime = leadTime;
        this.approvalDate = approvalDate;
        this.requiredOnJobsite = requiredOnJobsite;
        this.submitDate = submitDate;
        this.reviewDuration = reviewDuration;
        this.orderDelay = orderDelay;
        this.submittalNumber = submittalNumber;
        this.specNumber = specNumber;
        this.nextOrderBy = nextOrderBy;
        this.nextRequiredOnJobsite = nextRequiredOnJobsite;
        this.audits = audits;
        this.project = project;
        this.materialGroup = materialGroup;
        this.orders = orders;
        this.materialsSubmittals = materialsSubmittals;
    }
     

    static fromApiObject(json: any, timezone: string): Material {
        const newObject = new Material(
            json["id"],
            json["material_group_id"],
            json["archived"],
            json["material_name"],
            json["unit_of_measure"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["check_in_frequency"],
            json["project_id"],
            json["lead_time"],
            json["approval_date"] ? dayjs(json["approval_date"]).tz(timezone) : null,
            json["required_on_jobsite"] ? dayjs(json["required_on_jobsite"]).tz(timezone) : null,
            json["submit_date"] ? dayjs(json["submit_date"]).tz(timezone) : null,
            json["review_duration"],
            json["order_delay"],
            json["submittal_number"],
            json["spec_number"],
            json["next_order_by"] ? dayjs(json["next_order_by"]).tz(timezone) : null,
            json["next_required_on_jobsite"] ? dayjs(json["next_required_on_jobsite"]).tz(timezone) : null,
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["material_group"] == null ? json["material_group"] : MaterialGroup.fromApiObject(json["material_group"], timezone),
            json["orders"]?.map((item: any) => Order.fromApiObject(item, timezone)),
            json["materials_submittals"]?.map((item: any) => MaterialsSubmittal.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_group_id: this.materialGroupId,
            archived: this.archived,
            material_name: this.materialName,
            unit_of_measure: this.unitOfMeasure,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            check_in_frequency: this.checkInFrequency,
            project_id: this.projectId,
            lead_time: this.leadTime,
            approval_date: this.approvalDate,
            required_on_jobsite: this.requiredOnJobsite,
            submit_date: this.submitDate,
            review_duration: this.reviewDuration,
            order_delay: this.orderDelay,
            submittal_number: this.submittalNumber,
            spec_number: this.specNumber,
            next_order_by: this.nextOrderBy,
            next_required_on_jobsite: this.nextRequiredOnJobsite,
            audits: this.audits?.map(item => item.toApiObject()),
            orders: this.orders?.map(item => item.toApiObject()),
            materials_submittals: this.materialsSubmittals?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Material {
        const clone = Object.create(this) as Material;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
