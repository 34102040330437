import { trackPromise } from "react-promise-tracker";

interface email_sms {
    mobile?: string;
    email?: string;
    return_url: string | null;
    timestamp: string;
}

export function userOTPLogin(email_sms: email_sms): Promise<boolean> {
    const url = "/auth/login";

    if (email_sms.mobile) {
        email_sms.mobile = email_sms.mobile.trim();
    }
    if (email_sms.email) {
        email_sms.email = email_sms.email.trim();
    }

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(email_sms),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to send OTP");
            })
            .catch(() => {
                return false;
            })
    );
}

export function userOTPPasscode(user): Promise<boolean> {
    const url = "/auth/passcode";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to send OTP");
            })
            .catch(() => {
                return false;
            })
    );
}

export function createUser(user): Promise<boolean> {
    const url = "/signup";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to send OTP");
            })
            .catch(() => {
                return false;
            })
    );
}
