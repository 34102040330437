export class CacheableType {
    cacheable: number = 0;
    cacheableName: string = "";

    static SUBMITTAL: CacheableType = new CacheableType(0, "SUBMITTAL");
    static ACTIVITY: CacheableType = new CacheableType(1, "ACTIVITY");

    constructor(cacheable: number, cacheableName: string) {
        this.cacheable = cacheable;
        this.cacheableName = cacheableName;
    }

    static fromString(cacheableTypeStr: string): CacheableType {
        switch (cacheableTypeStr.toUpperCase()) {
            case "SUBMITTAL":
                return CacheableType.SUBMITTAL;
            case "ACTIVITY":
                return CacheableType.ACTIVITY;
        }

        return CacheableType.SUBMITTAL;
    }
}

// THIS ENUM SHOULD ALWAYS MATCH models/vendor_cache.rb's vendor_names ENUM
export enum IntegrationSource {
    user_upload,
    procore,
    procore_sandbox,
    autodesk_construction_cloud,
    cmic,
    primavera_p6,
    microsoft_project,
    asta_power_project,
}

export class VendorCache {
    id: number;
    cacheable_id: number;
    cacheable_type: CacheableType;
    integration_source: IntegrationSource;
    vendor_record_id: string;
    data: any;
    last_synced: Date;
    created_at: Date;
    updated_at: Date;

    constructor() {}

    static fromApiObject(obj: any): VendorCache {
        const cacheable = new VendorCache();

        cacheable.id = obj.id;
        cacheable.cacheable_id = obj.cacheable_id;

        cacheable.cacheable_type = CacheableType.fromString(obj.cacheable_type);
        cacheable.integration_source = obj.integration_source;
        cacheable.vendor_record_id = obj.vendor_record_id;
        cacheable.data = obj.data;
        cacheable.last_synced = new Date(obj.last_synced);
        cacheable.created_at = new Date(obj.created_at);
        cacheable.updated_at = new Date(obj.updated_at);

        return cacheable;
    }

    toApiObject(): any {
        return {
            id: this.id,
            cacheable_id: this.cacheable_id,
            cacheable_type: this.cacheable_type.cacheable,
            integration_source: this.integration_source,
            vendor_record_id: this.vendor_record_id,
            data: this.data,
            last_synced: this.last_synced,
        };
    }
}
