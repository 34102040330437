/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `VendorLink.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { VendorLink } from "./VendorLink";

import { Audit } from "./Audit";
import { Project } from "./Project";

export type integrationSource = "user_upload" | "procore" | "procore_sandbox" | "autodesk_construction" | "cmic" | "primavera_p6" | "microsoft_project" | "asta_power_project" | "faker";

export const integrationSourceValues = {"user_upload":0,"procore":1,"procore_sandbox":2,"autodesk_construction":3,"cmic":4,"primavera_p6":5,"microsoft_project":6,"asta_power_project":7,"faker":8};

export abstract class VendorLinkModel {
    id: number;
    integrationSource: integrationSource;
    sourceHash: string | null;
    sourceData: any | null;
    vendorLinkableType: string;
    vendorLinkableId: number;
    projectId: number;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    audits: Audit[] | undefined;
    project: Project | null | undefined;

     
    constructor(
        id: number,
        integrationSource: integrationSource,
        sourceHash: string | null,
        sourceData: any | null,
        vendorLinkableType: string,
        vendorLinkableId: number,
        projectId: number,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        audits: Audit[] | undefined,
        project: Project | null | undefined
    ) {
        this.id = id;
        this.integrationSource = integrationSource;
        this.sourceHash = sourceHash;
        this.sourceData = sourceData;
        this.vendorLinkableType = vendorLinkableType;
        this.vendorLinkableId = vendorLinkableId;
        this.projectId = projectId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.audits = audits;
        this.project = project;
    }
     

    static fromApiObject(json: any, timezone: string): VendorLink {
        const newObject = new VendorLink(
            json["id"],
            json["integration_source"],
            json["source_hash"],
            json["source_data"],
            json["vendor_linkable_type"],
            json["vendor_linkable_id"],
            json["project_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            integration_source: this.integrationSource,
            source_hash: this.sourceHash,
            source_data: this.sourceData,
            vendor_linkable_type: this.vendorLinkableType,
            vendor_linkable_id: this.vendorLinkableId,
            project_id: this.projectId,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            audits: this.audits?.map(item => item.toApiObject())
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): VendorLink {
        const clone = Object.create(this) as VendorLink;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
