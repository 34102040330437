import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { ChangeHistoryMessage, Material, MaterialGroup, Order } from "../Materials/materialTypes";

function getCommentableType(commentable: Order | Material | MaterialGroup): string {
    let commentableType: string;
    switch (true) {
        case commentable instanceof Order:
            commentableType = "Order";
            break;
        case commentable instanceof Material:
            commentableType = "Material";
            break;
        case commentable instanceof MaterialGroup:
            commentableType = "MaterialGroup";
            break;
        default:
            throw new Error("Unknown commentable type");
    }
    return commentableType;
}

export function createComment(
    project,
    comment: string,
    commentable: Order | Material | MaterialGroup,
    files: File[]
): Promise<any> {
    const url = `${Helper.baseUrlAltSubdomain(project.claimed_by_team_subdomain)}/projects/${
        project.unique_token
    }/comments`;

    const formData = new FormData();
    formData.append("body", comment);
    formData.append("commentable_type", getCommentableType(commentable));
    formData.append("commentable_id", commentable.id.toString());

    files.forEach((file) => {
        formData.append("files[]", file, file.name);
    });

    return trackPromise(
        fetch(url, {
            method: "post",
            credentials: "include",
            body: formData,
        })
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(() => {
            return Promise.reject();
        });
}

export function getComments(project, commentable: Order | Material | MaterialGroup): Promise<any> {
    const url = `${Helper.baseUrlAltSubdomain(project.claimed_by_team_subdomain)}/projects/${
        project.unique_token
    }/comments?commentable_type=${getCommentableType(commentable)}&commentable_id=${commentable.id}`;

    return Helper.plotFetch(url, {}, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })

        .then((data) => {
            return data.map((message: any) => ChangeHistoryMessage.fromApiObject(message));
        })
        .catch(() => {
            return Promise.reject();
        });
}
