import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";

/** Get the magic token's user info
 * @param magic_token User magic token
 * @param user_id User id associated with the magic token
 * @returns user full name
 */
export function getMagicTokenInfo(
    magic_token: string,
    token: string,
    preflight_passed: boolean = false
): Promise<any | null> {
    const preflight_passed_param = preflight_passed ? "?preflight_passed=true" : "";
    const url = `${Helper.baseUrlAltSubdomain("api")}/login/${magic_token}/${token}${preflight_passed_param}`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return null;
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

/**
 *  Login using the magic token
 * @param magic_token User magic token
 * @param user_id User id associated with the magic token
 * @returns status true or false
 */
export function loginWithMagicToken(token: string, magic_token: string): Promise<any | null> {
    if (magic_token) {
        const url = Helper.baseUrlAltSubdomain("api") + "/magic_tokens/";
        return trackPromise(
            fetch(url, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: token, magic_token: magic_token }),
                credentials: "include",
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    return null;
                })
                .then((response) => {
                    return response;
                })
                .catch(() => {
                    return null;
                })
        );
    }

    return Promise.resolve(null);
}
